import { ValidateIf } from 'class-validator';
import {
  LocalizedIsEmail,
  LocalizedIsNotEmpty,
} from '../../../common/validatorHelpers';
import { isEmpty } from 'ramda';

export class CreateUserDto {
  constructor() {
    this.email = '';
    this.password = '';
    this.externalId = '';
  }

  @LocalizedIsEmail()
  @ValidateIf((o) => isEmpty(o.externalId))
  email: string;

  @LocalizedIsNotEmpty()
  password: string;

  @LocalizedIsNotEmpty()
  @ValidateIf((o) => isEmpty(o.email))
  externalId: string;
}
