import { Avatar, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import {
  compose,
  filter,
  groupBy,
  indexBy,
  isEmpty,
  map,
  mergeRight,
  toPairs,
  uniqBy,
} from 'ramda';
import { useMemo } from 'react';
import { useAppSelector } from '../../app/hooks';
import {
  Match,
  Round,
  Tournament,
  useTournamentCustomSettingsControllerFindByTournamentQuery,
} from '../../app/services/futbolProdeApi';
import RoundSummary from './RoundSummary';
import { useCompanyTranslations } from '@futbolprode/ui-common';
import TournamentLogo from '../../common/TournamentLogo';

interface TournamentSummaryProps {
  tournament: Tournament;
  matchesSummary?: Match[];
  areMatchesLoading: boolean;
}

function TournamentSummaryByDate({
  tournament,
  matchesSummary,
  areMatchesLoading,
}: TournamentSummaryProps) {
  const { date } = useAppSelector((state) => state.summaryNavigation.date);
  const { tournamentName } = useCompanyTranslations();

  const roundsIndex = useMemo(
    () =>
      compose(
        indexBy((it: Round) => it.id.toString()),
        uniqBy((it: Round) => it.id),
        map((it: Match) => it.round),
      )(matchesSummary ?? []),
    [matchesSummary],
  );

  const { data: tournamentCustomSettings } =
    useTournamentCustomSettingsControllerFindByTournamentQuery(
      tournament.id.toString(),
    );

  const matchesByRound = useMemo(
    () =>
      compose(
        groupBy((it: Match) => it.round.id.toString()),
        filter(
          (it: Match) =>
            it.round.position >=
              (tournamentCustomSettings?.round?.position || 0) &&
            it.round.tournament.id === tournament.id,
        ),
      )(matchesSummary ?? []),
    [matchesSummary, tournament, tournamentCustomSettings],
  );

  const makeRound = (roundId: string, matches: Match[]) =>
    mergeRight(roundsIndex[roundId], { matches });

  return isEmpty(matchesByRound) ? null : (
    <Paper elevation={1} className="summary-predictions">
      <Stack p={2}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{`${tournamentName(tournament)} - ${
            tournament.season
          }`}</Typography>
          <TournamentLogo>
            <Avatar
              variant="square"
              src={tournament.logoUrl}
              alt={tournament.name}
              sx={{ width: 64, height: 64 }}
            />
          </TournamentLogo>
          <Typography
            variant="h6"
            sx={{
              visibility: 'hidden',
              display: { xs: 'none', md: 'initial' },
            }}
          >
            {tournament.name}
          </Typography>
        </Stack>
        <Stack>
          {toPairs(matchesByRound).map(([roundId, matches]) => (
            <RoundSummary
              tournamentId={tournament.id}
              areMatchesLoading={areMatchesLoading}
              round={makeRound(roundId, matches)}
              selectedDate={date}
            />
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
}

export default TournamentSummaryByDate;
