import { Grid, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { futbolProdeApi } from '../../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { Routes, useCompanyRoutes } from '../../../router';
import { AdmissionFormProps } from '../AdmissionContainer';
import { Input } from './Input';

export function ExternalIdSignUpForm({ company }: AdmissionFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const invitationNotFoundMessage = t('signup.errors.invitationNotFound');
  const invalidIdentifierMessage = t('signup.errors.invalidIdentifier');
  const navigate = useNavigate();
  const { rootRoute } = useCompanyRoutes();

  const [trigger, result, lastPromiseInfo] =
    futbolProdeApi.useLazyInvitationsControllerFindOneByExternalIdQuery();
  const handleSubmit = async ({ externalId }: { externalId: string }) => {
    try {
      const invitation = await trigger(externalId).unwrap();
      navigate(rootRoute(Routes.ACTIVATE), { state: { invitation, company } });
    } catch (e) {
      // TODO additional error handling here?
    }
  };

  return (
    <Formik
      initialValues={{ externalId: '' }}
      onSubmit={handleSubmit}
      validate={(input) => {
        if (input.externalId.length === 0) {
          return { externalId: invalidIdentifierMessage };
        }
        return {};
      }}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <span style={{ color: 'inherit' }}>
              {t('signup.alreadyHaveAccountQuestion')}
            </span>{' '}
            <Link to={'../' + Routes.LOGIN} style={{ color: 'inherit' }}>
              {t('signup.signin')}
            </Link>
          </Grid>
          <form onSubmit={handleSubmit}>
            <Input
              label={t('signup.enterJobMail', {
                field: t('user.identifier', {
                  ns: 'company',
                  defaultValue: 'mail',
                }),
              })}
              value={values.externalId as string}
              name={'externalId'}
              onChange={handleChange}
              error={
                errors.externalId ||
                (result.isError && lastPromiseInfo.lastArg === values.externalId
                  ? invitationNotFoundMessage
                  : undefined)
              }
            />
            <LoadingButton
              loading={result.isLoading}
              fullWidth
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                fontWeight: 600,
                textTransform: 'capitalize',
              }}
            >
              {t('signup.continue')}
            </LoadingButton>
          </form>
        </Grid>
      )}
    </Formik>
  );
}
