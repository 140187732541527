import { RankingTable } from '../features/rankings/page/RankingTable';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import {
  selectActiveRanking,
  setPagination,
} from '../features/rankings/activeRankingSlice';
import {
  CombinedRoundsRankingDefinition,
  Ranking,
  Tournament,
  useCombinedRoundsRankingDefinitionsControllerFindAllQuery,
  useCompaniesControllerFindMeQuery,
  useRankingsControllerCombinedRoundsRankingQuery,
  useRankingsControllerGlobalRankingQuery,
  useRankingsControllerRoundRankingQuery,
  useRankingsControllerTournamentRankingQuery,
  useRoundsControllerFindRecentRoundsInTournamentQuery,
  useTournamentsControllerFindAllByCompanyQuery,
} from '../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AreaRanking } from '../app/services/futbolProdeApi';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import {
  useI18nHelpers,
  useDreamFinalVisibilityQuery,
  useCompanyTranslations,
} from '@futbolprode/ui-common';
import { parseISO } from 'date-fns';
import { isEmpty, isNil, last } from 'ramda';
import { useSearchParams } from 'react-router-dom';
import useCompanySettingsQuery from '../common/hooks/useCompanySettingsQuery';
import SplitLayout from './SplitLayout';
import RankingsTabs from '../features/rankings/page/RankingsTabs';
import useRankingHelpers from '../common/hooks/useRankingHelpers';
import { onlyIf, compact } from '../common/ramdaExtensions';
import { useDebounce } from 'use-debounce';

function useActiveRankingQuery({
  recentCombinedRounds,
  isShowingGeneralRanking,
  isShowingRounds,
  tournaments,
  selectedTournament,
  search,
}: {
  recentCombinedRounds: CombinedRoundsRankingDefinition[];
  isShowingGeneralRanking: boolean;
  isShowingRounds: boolean;
  tournaments?: Tournament[];
  selectedTournament?: Tournament;
  search?: string;
}) {
  const [searchParams] = useSearchParams();
  const maybeAreaId = searchParams.get('areaId') ?? undefined;
  const areaId = maybeAreaId ? parseInt(maybeAreaId) : undefined;
  const maybeAreaLevel = searchParams.get('areaLevel') ?? undefined;
  const areaLevel = maybeAreaLevel ? parseInt(maybeAreaLevel) : undefined;
  const dispatch = useAppDispatch();

  // Reinicia paginación cuando cambia el area o el level
  useEffect(() => {
    dispatch(setPagination({ skip: 0, take: 20 }));
  }, [dispatch, areaId, areaLevel]);

  const { roundId, skip, take } = useAppSelector(selectActiveRanking);

  const useCombinedRounds = useMemo(
    () => !isNil(recentCombinedRounds) && !isEmpty(recentCombinedRounds),
    [recentCombinedRounds],
  );

  const commonParams = {
    search,
    skip,
    take,
    areaId,
    areaLevel,
  };

  const { data: globalRanking, isFetching: isFetchingGlobal } =
    useRankingsControllerGlobalRankingQuery(commonParams);

  const { data: tournamentRanking, isFetching: isFetchingTournament } =
    useRankingsControllerTournamentRankingQuery(
      !isShowingGeneralRanking && tournaments && selectedTournament
        ? {
            ...commonParams,
            id: selectedTournament.id,
          }
        : skipToken,
    );

  const { data: roundRanking, isFetching: isFetchingRound } =
    useRankingsControllerRoundRankingQuery(
      !isShowingGeneralRanking && roundId && !useCombinedRounds
        ? { ...commonParams, id: roundId }
        : skipToken,
    );

  const { data: combinedRoundRanking, isFetching: isFetchingCombinedRound } =
    useRankingsControllerCombinedRoundsRankingQuery(
      !isShowingGeneralRanking && roundId && useCombinedRounds
        ? {
            ...commonParams,
            id: roundId,
          }
        : skipToken,
    );

  const activeRankingWithPagination = useMemo(
    () =>
      isShowingGeneralRanking
        ? globalRanking
        : isShowingRounds
        ? useCombinedRounds
          ? combinedRoundRanking
          : roundRanking
        : tournamentRanking,
    [
      isShowingGeneralRanking,
      globalRanking,
      isShowingRounds,
      useCombinedRounds,
      combinedRoundRanking,
      roundRanking,
      tournamentRanking,
    ],
  );

  return {
    data: activeRankingWithPagination,
    isFetching: [
      isFetchingGlobal,
      isFetchingTournament,
      isFetchingRound,
      isFetchingCombinedRound,
    ].some((it) => it),
  };
}

function RankingsLayout() {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const { tabIndex, mode } = useAppSelector(selectActiveRanking);
  const { t } = useTranslation();
  const { tournamentName } = useCompanyTranslations();

  const isShowingGeneralRanking = useMemo(() => tabIndex === 0, [tabIndex]);
  const isShowingRounds = useMemo(() => mode === 'rounds', [mode]);
  const { data: tournaments } = useTournamentsControllerFindAllByCompanyQuery();

  const selectedTournament = useMemo(
    () => (isShowingGeneralRanking ? undefined : tournaments?.[tabIndex - 1]),
    [isShowingGeneralRanking, tournaments, tabIndex],
  );
  const currentRankingName = useMemo(
    () =>
      isShowingGeneralRanking
        ? t('ranking.positions.global')
        : tournamentName(selectedTournament) ?? '',
    [isShowingGeneralRanking, selectedTournament, t, tournamentName],
  );

  const { data: recentAndCurrentRounds, isLoading: recentRoundsAreLoading } =
    useRoundsControllerFindRecentRoundsInTournamentQuery(
      isShowingGeneralRanking || !selectedTournament
        ? skipToken
        : selectedTournament.id.toString(),
      { refetchOnMountOrArgChange: true },
    );

  const recentRounds = useMemo(
    () => recentAndCurrentRounds?.rounds,
    [recentAndCurrentRounds],
  );
  const currentRound = useMemo(
    () => recentAndCurrentRounds?.currentRound,
    [recentAndCurrentRounds],
  );

  const {
    data: combinedRoundsDefinitions,
    isLoading: combinedDefinitionsLoading,
  } = useCombinedRoundsRankingDefinitionsControllerFindAllQuery(
    recentRounds ? undefined : skipToken,
  );
  const recentRoundIds = useMemo(
    () => recentRounds?.map((r) => r.id) ?? [],
    [recentRounds],
  );
  const recentCombinedRounds = useMemo(
    () =>
      (combinedRoundsDefinitions ?? []).filter((it) =>
        it.rounds
          .map((r) => r.id)
          .some((roundId) => recentRoundIds.includes(roundId)),
      ),
    [combinedRoundsDefinitions, recentRoundIds],
  );

  const currentCombinedRound = useMemo(
    () =>
      recentCombinedRounds.find((it) =>
        it.rounds.some((r) => r.id === currentRound?.id),
      ) ?? last(recentCombinedRounds),
    [recentCombinedRounds, currentRound],
  );

  const activeCurrentRound = useMemo(
    () => currentCombinedRound ?? currentRound,
    [currentCombinedRound, currentRound],
  );

  const activeRecentRounds = useMemo(
    () => (isEmpty(recentCombinedRounds) ? recentRounds : recentCombinedRounds),
    [recentCombinedRounds, recentRounds],
  );

  const { data: activeRankingWithPagination, isFetching: isRankingFetching } =
    useActiveRankingQuery({
      isShowingRounds,
      isShowingGeneralRanking,
      recentCombinedRounds,
      tournaments,
      selectedTournament,
      search: debouncedSearchTerm,
    });
  const activeRanking: Ranking[] | AreaRanking[] | undefined =
    activeRankingWithPagination?.data;
  const activeCount: number | undefined =
    activeRankingWithPagination?.pagination?.count;
  const activePage: number | undefined =
    activeRankingWithPagination?.pagination?.page;

  const {
    data: { showForGeneralRanking, showForTournament },
  } = useDreamFinalVisibilityQuery();

  const showDreamFinalColumns = useMemo(() => {
    const isGeneralRankingWithDreamFinal = () =>
      isShowingGeneralRanking && showForGeneralRanking;

    const isTournamentRankingWithDreamFinal = () =>
      !isNil(selectedTournament) &&
      !isShowingRounds &&
      showForTournament(selectedTournament);

    return (
      isGeneralRankingWithDreamFinal() || isTournamentRankingWithDreamFinal()
    );
  }, [
    isShowingGeneralRanking,
    isShowingRounds,
    selectedTournament,
    showForGeneralRanking,
    showForTournament,
  ]);

  const { data: company } = useCompaniesControllerFindMeQuery();
  const { formatDate } = useI18nHelpers();
  const {
    data: { hasAreas },
  } = useCompanySettingsQuery();

  const { userNameForRanking } = useRankingHelpers();

  const rankingColumns: any = useMemo(
    () =>
      compact([
        {
          field: 'name',
          headerName: t('ranking.positions.firstAndLastName'),
          description: t('ranking.positions.firstAndLastName'),
          minWidth: 300,
          flex: 1,
          align: 'left',
          headerAlign: 'left',
          valueGetter: (params: GridValueGetterParams) =>
            userNameForRanking(params.row.user),
        },
        onlyIf(showDreamFinalColumns, {
          field: 'total',
          headerName: t('ranking.positions.total'),
          description: t('ranking.positions.total'),
          minWidth: 100,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params: GridValueGetterParams) =>
            params.row.points + (params.row.dreamFinalPoints ?? 0),
        }),
        {
          field: 'points',
          headerName: t('ranking.positions.points'),
          description: t('ranking.positions.points'),
          minWidth: 100,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
        },
        onlyIf(showDreamFinalColumns, {
          field: 'dreamFinalPoints',
          headerName: t('ranking.positions.dreamFinalPoints'),
          description: t('ranking.positions.dreamFinalPoints'),
          minWidth: 100,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params: GridValueGetterParams) =>
            params.row.dreamFinalPoints ?? '-',
        }),
        {
          field: 'exactMatches',
          headerName: t('ranking.positions.exactMatches'),
          description: t('ranking.positions.exactMatches'),
          minWidth: 100,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'predictionsCount',
          headerName: t('ranking.positions.predictionsCount'),
          description: t('ranking.positions.predictionsCount'),
          minWidth: 100,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: 'createdAt',
          headerName: t('ranking.positions.createdAt'),
          description: t('ranking.positions.createdAt'),
          minWidth: 200,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params: GridValueGetterParams) =>
            formatDate('P')(parseISO(params.row.user.createdAt)) || '',
        },
        onlyIf(hasAreas, {
          field: 'areas',
          headerName: t('ranking.positions.areas'),
          description: t('ranking.positions.areas'),
          minWidth: 200,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params: GridValueGetterParams) =>
            [
              params.row.user.areaLevel1,
              params.row.user.areaLevel2,
              params.row.user.areaLevel3,
            ]
              .filter((it) => (it?.name ?? '') !== '')
              .map((it) => it.name)
              .join(', '),
        }),
      ]),
    [t, formatDate, showDreamFinalColumns, hasAreas, userNameForRanking],
  );

  return (
    <SplitLayout
      title={currentRankingName}
      logoUrl={
        isShowingGeneralRanking || company?.disableTournamentDetails
          ? company?.isologoUrl
          : selectedTournament?.logoUrl
      }
      HeaderContent={<RankingsTabs tournaments={tournaments ?? []} />}
      MainContent={
        <RankingTable
          isGlobalRanking={isShowingGeneralRanking}
          selectedTournament={selectedTournament}
          recentRounds={activeRecentRounds as any}
          recentRoundsAreLoading={
            recentRoundsAreLoading || combinedDefinitionsLoading
          }
          currentRound={activeCurrentRound}
          columns={rankingColumns}
          activeRanking={activeRanking}
          activeCount={activeCount}
          activePage={activePage}
          isRankingLoading={isRankingFetching}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      }
    />
  );
}

export default RankingsLayout;
