import {
  Autocomplete,
  Box,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Team } from '../../app/services/futbolProdeApi';

export default function DreamFinalTeamSelector({
  isLoading,
  disabled,
  value,
  options,
  onChange,
  position,
}: {
  isLoading: boolean;
  disabled: boolean;
  onChange: (value: Team | null) => void;
  options: Team[];
  position: number;
  value: Team | null;
}) {
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      display="flex"
      my={2}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Typography>{t(`dreamFinal.team${position}`)}</Typography>
      {isLoading ? (
        <Skeleton width="100%" />
      ) : (
        <Autocomplete
          disabled={disabled}
          value={value}
          onChange={(e, value) => onChange(value)}
          disablePortal
          options={options}
          sx={{ width: '50%' }}
          renderOption={(params: any, option: any) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...params}
            >
              <img loading="lazy" width="20" src={option.logoUrl} alt="Logo" />
              {option.name}
            </Box>
          )}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('dreamFinal.choose') as string}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      )}
    </Stack>
  );
}
