import { IsEmail } from 'class-validator';
import { User } from '../../app/services/futbolProdeApi';
import { LocalizedIsNotEmpty } from '../../common/validatorHelpers';

export class ProfileSupportEmailFormDto {
  constructor(data?: User) {
    this.name = data?.name ?? '';
    this.userEmail = data?.email ?? '';
    this.emailToAnswer = '';
    this.inquiry = '';
  }

  @LocalizedIsNotEmpty()
  name: string;

  @LocalizedIsNotEmpty()
  @IsEmail()
  userEmail: string;

  emailToAnswer?: string;

  @LocalizedIsNotEmpty()
  inquiry: string;
}
