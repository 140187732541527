import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useGameRulesControllerTermsAndConditionsQuery } from '../../../app/services/futbolProdeApi';
import { isNilOrEmpty } from '../../../common/ramdaExtensions';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';

export default function TermsAndConditionsSection({
  value,
  onChange,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  const { data, isLoading } = useGameRulesControllerTermsAndConditionsQuery();
  const skip = isNilOrEmpty(data?.termsAndConditions);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && skip) {
      onChange(true);
    }
  }, [skip, isLoading, onChange]);

  return skip ? null : (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {t('termsAndConditions.pages.word')}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'justify' }}>
            {parse(
              DOMPurify.sanitize(data?.termsAndConditions ?? '', {
                FORBID_ATTR: ['style'],
                USE_PROFILES: { html: true },
              }),
              { trim: true },
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <FormControlLabel
        control={
          <Checkbox
            id="termsAndConditions"
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
          />
        }
        label={
          <Typography variant="body2" color="inherit">
            <Trans i18nKey="termsAndConditions.confirmAccept">
              Acepto los
              <Link
                component="button"
                onClick={() => setDialogOpen(true)}
                color="inherit"
              >
                términos y condiciones
              </Link>
              .
            </Trans>
          </Typography>
        }
      />
    </>
  );
}
