import {
  Grid,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from '@mui/material';
import { FormikProps } from 'formik';
import { Company } from '../../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { SignUpUserDto } from './SignUpUserDto';
import { Case, Default, Switch } from 'react-if';
import { Input } from './Input';
import { parseISO, formatISOWithOptions } from 'date-fns/fp';
import { Nullable } from 'typescript-nullable';
import { DatePicker } from '@mui/x-date-pickers';

export function SignUpExtraFields({
  company,
  values,
  setFieldValue,
  handleChange,
  submitCount,
}: FormikProps<SignUpUserDto> & { company?: Company }) {
  const { t } = useTranslation();
  return (
    <>
      {((company?.extraFields as any[]) || []).map((extraField, i) => {
        const name = `extraFields[${i}].value`;
        return (
          <Grid item xs={12} key={extraField.name + i}>
            <Switch>
              <Case condition={extraField.type === 'date'}>
                <InputLabel
                  htmlFor={name}
                  required={extraField.isRequired}
                  sx={{
                    color: 'inherit',
                  }}
                >
                  {extraField.name}
                </InputLabel>
                <DatePicker
                  name={name}
                  value={Nullable.map(
                    parseISO,
                    values.extraFields?.[i].value as string,
                  )}
                  onChange={(newValue: Date | null) => {
                    setFieldValue(
                      name,
                      Nullable.map(
                        formatISOWithOptions({ representation: 'date' }),
                        newValue,
                      ),
                    );
                  }}
                />
                {submitCount > 0 && extraField.isRequired && (
                  <FormHelperText error>
                    {!values.extraFields?.[i].value
                      ? t('validations.common.isNotEmpty')
                      : undefined}
                  </FormHelperText>
                )}
              </Case>
              <Case condition={extraField.type === 'boolean'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`extraFields[${i}].name`}
                      name={name}
                      checked={
                        (values.extraFields?.[i].value as boolean) || false
                      }
                      onChange={handleChange}
                    />
                  }
                  label={extraField.name}
                />
              </Case>
              <Default>
                <Input
                  value={(values.extraFields?.[i].value as string) || ''}
                  type={extraField.type as string}
                  name={name}
                  required={extraField.isRequired}
                  label={extraField.name}
                  onChange={handleChange}
                  error={
                    submitCount > 0 &&
                    extraField.isRequired &&
                    !values.extraFields?.[i].value
                      ? 'validations.common.isNotEmpty'
                      : undefined
                  }
                />
              </Default>
            </Switch>
          </Grid>
        );
      })}
    </>
  );
}
