import { useMemo } from 'react';
import { useTournamentsControllerGetSummaryQuery } from '../../app/services/futbolProdeApi';

export function useCompanyHasManyTournamentsQuery() {
  const {
    data: tournamentsSummary,
    isLoading,
    currentData,
    isFetching,
  } = useTournamentsControllerGetSummaryQuery();

  const data = useMemo(
    () => (tournamentsSummary?.count ?? 1) > 1,
    [tournamentsSummary],
  );

  return { data, isLoading, isFetching, currentData };
}
