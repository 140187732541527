import { isEmpty } from 'ramda';
import { Match } from '../../app/services/futbolProdeApi';
import {
  Card,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.secondary.light,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: theme.palette.tertiary.main,
  },
}));

export default function PredictionsProgress({ matches }: { matches: Match[] }) {
  const { t } = useTranslation();
  const total = matches.length;
  const predicted = matches.filter((it) => !isEmpty(it.predictions)).length;

  return (
    <Card>
      <CardContent
        sx={{
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
        }}
      >
        <Stack spacing={2}>
          <Typography>
            {total === predicted
              ? t('predictionsProgress.all')
              : t('predictionsProgress.title', { predicted, total })}
          </Typography>
          <BorderLinearProgress
            variant="determinate"
            value={(predicted / total) * 100}
            sx={{ width: '100%' }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
