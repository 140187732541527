import {
  Avatar,
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Tabs,
  styled,
  Tab,
  Badge,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import EditIcon from '@mui/icons-material/Edit';
import WithAppBar from '../common/WithAppBar';
import ScrollToTopFab from './ScrollToTopFab';
import { useUploadFileMutation } from '@futbolprode/ui-common';
import { useTranslation } from 'react-i18next';
import {
  User,
  useRankingsControllerLatestRankingsMeQuery,
  useUsersControllerFindMeQuery,
  useUsersControllerUpdateMutation,
} from '../app/services/futbolProdeApi';
import React, { useMemo, useState } from 'react';
import { PersonalDataForm } from '../features/profile/PersonalDataForm';
import { PersonalSupportForm } from '../features/profile/PersonalSupportForm';
import CropperModal from '../features/profile/CropperModal';
import { compact } from '../common/ramdaExtensions';
import { isEmpty } from 'ramda';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'transparent',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.main,
    height: '5px',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.secondary.main,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
}));

const AppContainer = ({
  children,
  isMobile,
}: {
  children: JSX.Element;
  isMobile: boolean;
}) => (
  <Container maxWidth="xl" sx={{ ...(isMobile ? { padding: 0 } : {}) }}>
    {children}
  </Container>
);

const defaultPortraitPicture =
  'https://d1g4ax6chcx3tu.cloudfront.net/4/80a84510-a1fd-4576-b6fc-3308fa72e171.jpg';

const areaString = (user?: User) => {
  const names = compact([
    user?.areaLevel1?.name,
    user?.areaLevel2?.name,
    user?.areaLevel3?.name,
  ]);
  return isEmpty(names) ? undefined : names.join(' / ');
};

function ProfileLayout({ tabIndex = 0 }: { tabIndex?: number }) {
  const [uploadFile] = useUploadFileMutation();
  const { t } = useTranslation();
  const { data: user } = useUsersControllerFindMeQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: me,
    isLoading: userIsLoading,
    isUninitialized,
  } = useUsersControllerFindMeQuery();

  const { data: rankings } = useRankingsControllerLatestRankingsMeQuery();

  const [updateUserPicture] = useUsersControllerUpdateMutation();

  const profilePicture = React.useRef(null);
  const portraitPicture = React.useRef(null);
  const [cropperModalOpen, setCropperModalOpen] = React.useState(false);

  const [profileTabIndex, setProfileTabIndex] = useState(tabIndex);

  const indexToComponent = useMemo(
    () =>
      [<PersonalDataForm me={me} />, <PersonalSupportForm me={me} />][
        profileTabIndex
      ],
    [me, profileTabIndex],
  );

  return (
    <>
      <input
        type="file"
        ref={profilePicture}
        onChange={async (e) => {
          const formData = new FormData();
          formData.append('file', e.target?.files?.[0] as any);
          const { cdnUrl } = await uploadFile(formData).unwrap();
          updateUserPicture({
            id: me!.id,
            updateUserDto: {
              profilePictureUrl: cdnUrl,
            },
          });
        }}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        ref={portraitPicture}
        onChange={async (e) => {
          const formData = new FormData();
          formData.append('file', e.target?.files?.[0] as any);
          const { cdnUrl } = await uploadFile(formData).unwrap();
          updateUserPicture({
            id: me!.id,
            updateUserDto: {
              portraitPictureUrl: cdnUrl,
            },
          });
        }}
        style={{ display: 'none' }}
      />
      <CropperModal
        image={user?.profilePictureUrl ?? ''}
        open={cropperModalOpen}
        setOpen={setCropperModalOpen}
        user={user}
      />
      <WithAppBar>
        <Box
          py={isMobile ? 0 : 1}
          sx={{
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
          }}
        >
          <AppContainer isMobile={isMobile}>
            <Grid xs={12} display="flex" justifyContent="center">
              <img
                id="portraitPicture"
                src={
                  !isUninitialized && !userIsLoading && !me?.portraitPictureUrl
                    ? defaultPortraitPicture
                    : me?.portraitPictureUrl
                }
                alt="Portrait"
                style={{
                  width: '100%',
                  margin: '0 auto',
                  height: isMobile ? '150px' : '300px',
                  objectFit: 'cover',
                }}
              />
              <AddAPhotoIcon
                fontSize="large"
                sx={{
                  position: 'absolute',
                  left: '80%',
                  top: isMobile ? 200 : 350,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  (portraitPicture.current as any).click();
                }}
              />
            </Grid>
          </AppContainer>
        </Box>
        <AppContainer isMobile={false}>
          <>
            <Stack
              direction="row"
              display="flex"
              alignItems="center"
              spacing={isMobile ? 0 : 6}
              justifyContent={isMobile ? 'center' : 'flex-start'}
            >
              <Box
                sx={{
                  position: 'absolute',
                  ...(isMobile
                    ? { display: 'flex', placeContent: 'center', width: '100%' }
                    : {}),
                }}
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <>
                      <AddAPhotoIcon
                        fontSize="large"
                        sx={{ mb: isMobile ? 45 : 20, cursor: 'pointer' }}
                        onClick={() => {
                          (profilePicture.current as any).click();
                        }}
                      />
                      {me?.profilePictureUrl ? (
                        <EditIcon
                          fontSize="large"
                          sx={{ mb: isMobile ? 45 : 20, cursor: 'pointer' }}
                          onClick={() => {
                            setCropperModalOpen(true);
                          }}
                        />
                      ) : null}
                    </>
                  }
                >
                  <Avatar
                    src={me?.profilePictureUrl ?? ''}
                    sx={{
                      width: { xs: '168px', lg: '12vw' },
                      height: { xs: '168px', lg: '12vw' },
                      bottom: isMobile ? 180 : '8vh',
                      ml: isMobile ? 0 : 8,
                      outlineStyle: 'solid',
                      outlineColor: 'white',
                      outlineWidth: '10px',
                    }}
                  />
                </Badge>
              </Box>
              <Stack
                sx={{
                  position: 'relative',
                  left: isMobile ? 0 : { md: '20vw', lg: '15vw' },
                  marginBottom: isMobile ? '12vh' : undefined,
                }}
                direction={isMobile ? 'column' : 'row'}
                spacing={2}
                alignItems="center"
                mt={12}
              >
                <Stack display="flex" alignItems="center">
                  <Typography fontWeight={600} sx={{ fontSize: '2.2rem' }}>
                    {user?.name ?? ''}
                  </Typography>
                  <Typography variant="h6">{areaString(user) ?? ''}</Typography>
                </Stack>
                {isMobile ? null : (
                  <Divider orientation="vertical" variant="middle">
                    <Typography
                      color="textSecondary"
                      variant="h2"
                      fontWeight={300}
                    >
                      |
                    </Typography>
                  </Divider>
                )}
                <Stack direction="row" spacing={8}>
                  <Stack
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h5" fontWeight={600}>
                      {rankings?.global.position ?? '-'}
                    </Typography>
                    <Typography>{t('profile.position')}</Typography>
                  </Stack>
                  <Stack
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h5" fontWeight={600}>
                      {rankings?.global.points ?? '-'}
                    </Typography>
                    <Typography>{t('profile.points')}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider sx={{ mt: 2, width: '100%' }} />
            <StyledTabs variant="scrollable" value={0} onChange={() => {}}>
              <StyledTab label={t('profile.personalInfo')} />
            </StyledTabs>
            <Box mt={4} mb={6}>
              <Paper sx={{ borderRadius: 3 }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      borderRight: { xs: 'none', md: '1px solid gray' },
                      borderBottom: { xs: '1px solid gray', md: 'none' },
                    }}
                  >
                    <List>
                      <ListItem>
                        <Typography variant="h5">
                          {t('profile.personalInfo')}
                        </Typography>
                      </ListItem>
                      <ListItem onClick={() => setProfileTabIndex(0)}>
                        <ListItemButton>
                          <ListItemText primary={t('profile.personalData')} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem onClick={() => setProfileTabIndex(1)}>
                        <ListItemButton>
                          <ListItemText primary={t('profile.support')} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Grid>
                  {indexToComponent}
                </Grid>
              </Paper>
            </Box>
          </>
        </AppContainer>
        <ScrollToTopFab />
      </WithAppBar>
    </>
  );
}

export default ProfileLayout;
