import Slider from 'react-slick';
import TriviaSelectorCard from './TriviaSelectorCard';
import { Grid, GridProps, IconButton, styled, useTheme } from '@mui/material';
import useIsMobileQuery from '../../common/hooks/useIsMobileQuery';
import { useRef } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TriviaShowcaseSectionProps } from './TriviaShowcaseSection';

const ArrowButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

const ArrowButtonContainer = (props: GridProps) => (
  <Grid item container xs={0.5} lg={0.3} {...props} />
);

export function TriviaCardSlider({
  items,
  isLoading,
}: TriviaShowcaseSectionProps) {
  const theme = useTheme();
  const isMobile = useIsMobileQuery();
  const showOnlyOneCard = isMobile || items.length === 1;
  const slidesToShow = showOnlyOneCard ? 1 : 2;
  const sliderRef = useRef<Slider | null>(null);

  return (
    <Grid container alignItems="center">
      <ArrowButtonContainer justifyContent="flex-start">
        <ArrowButton onClick={() => sliderRef.current?.slickPrev()}>
          <KeyboardArrowLeftIcon />
        </ArrowButton>
      </ArrowButtonContainer>
      <Grid item xs={11} lg={11.4}>
        <style>{`
        /* Espacio entre tarjetas */
        .slick-current {
          padding-right: ${showOnlyOneCard ? 0 : theme.spacing(3)};
        }

        .slick-list {
          padding-bottom: ${theme.spacing(2)};
        }

        /* Tamaño y color de los dots */
        .slick-dots li button:before {
          font-size: 15px;
          color: ${theme.palette.secondary.contrastText};
        }
        
        .slick-dots li.slick-active button:before {
          color: ${theme.palette.secondary.contrastText};
        }
      `}</style>
        <Slider
          ref={sliderRef}
          slidesToShow={slidesToShow}
          autoplaySpeed={5000}
          arrows={false}
          swipeToSlide
          dots
          autoplay
          pauseOnHover
        >
          {items.map((value) => (
            <TriviaSelectorCard
              value={value}
              isLoading={isLoading}
              key={value.id}
            />
          ))}
        </Slider>
      </Grid>
      <ArrowButtonContainer justifyContent="flex-end">
        <ArrowButton onClick={() => sliderRef.current?.slickNext()}>
          <KeyboardArrowRightIcon />
        </ArrowButton>
      </ArrowButtonContainer>
    </Grid>
  );
}
