import { Box, Stack, Typography } from '@mui/material';
import { Company } from '../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export default function ComingSoonPage({ company }: { company: Company }) {
  const { t } = useTranslation();
  const color = useMemo(
    () => company?.comingSoonModeTextColor ?? 'secondary.contrastText',
    [company],
  );

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      height="100vh"
      display="flex"
      sx={{
        backgroundImage: `url("${
          company?.comingSoonModeBackgroundImageUrl ?? ''
        }")`,
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      <Stack width={700} maxWidth="90%" alignItems="center">
        <img
          src={company.logoUrl}
          alt="company-logo"
          style={{ maxHeight: 400, width: 450, maxWidth: '90%' }}
        />
        <Typography
          variant="h2"
          fontWeight={500}
          textAlign="center"
          mt={4}
          sx={{ color }}
        >
          {t('comingSoonMode.title', { ns: 'company' })}
        </Typography>
        <Typography variant="h6" textAlign="center" mt={3} sx={{ color }}>
          {t('comingSoonMode.subtitle', {
            ns: 'company',
            defaultValue: '',
          })}
        </Typography>
      </Stack>
    </Box>
  );
}
