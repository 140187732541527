import { Dispatch, SetStateAction } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Input } from './Input';

export function PasswordField({
  name,
  placeholder,
  value,
  onChange,
  errors,
  visibilityHook,
  label,
}: {
  name: string;
  placeholder: string;
  value: string;
  onChange: any;
  errors: any;
  visibilityHook: [boolean, Dispatch<SetStateAction<boolean>>];
  label?: string;
}) {
  const [show, setShow] = visibilityHook;
  return (
    <Box sx={{ mt: 1, mb: 2 }}>
      <Input
        value={value}
        name={name}
        label={label ?? ''}
        placeholder={placeholder}
        onChange={onChange}
        error={errors}
        type={show ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => setShow((prevShow) => !prevShow)}
                edge="end"
                tabIndex={-1}
              >
                {show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
