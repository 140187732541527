import { Stack } from '@mui/material';
import TournamentSummaryByDate from './TournamentSummaryByDate';
import {
  useTournamentsControllerGetSummaryQuery,
  useRoundsControllerSummaryByDateQuery,
} from '../../app/services/futbolProdeApi';
import { useAppSelector } from '../../app/hooks';
import { currentTimezone } from '../../common/dateTimeUtils';
import { useMemo } from 'react';
import PredictionsProgress from './PredictionsProgress';

export default function MatchesByDateVisualization() {
  const { data: tournamentsSummary } =
    useTournamentsControllerGetSummaryQuery();

  const { date } = useAppSelector((state) => state.summaryNavigation.date);

  const {
    data: matchesSummary,
    isFetching,
    currentData,
  } = useRoundsControllerSummaryByDateQuery({
    date,
    timezone: currentTimezone(),
  });

  const areMatchesLoading = useMemo(
    () => isFetching && !currentData,
    [isFetching, currentData],
  );

  return (
    <Stack spacing={2}>
      <PredictionsProgress matches={matchesSummary ?? []} />
      {tournamentsSummary?.data.map((tournament) => (
        <TournamentSummaryByDate
          tournament={tournament}
          matchesSummary={matchesSummary}
          areMatchesLoading={areMatchesLoading}
          key={tournament.id}
        />
      ))}
    </Stack>
  );
}
