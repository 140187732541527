import {
  Grid,
  Box,
  Stack,
  CardContent,
  useTheme,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  Company,
  useCompaniesControllerFindMeQuery,
} from '../../app/services/futbolProdeApi';
import { Switch, Case, Default, Unless } from 'react-if';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { RoundedCard } from '../../common/MuiExtensions';
import { useCallback } from 'react';
import { isNil } from 'ramda';
import ComingSoonPage from './ComingSoonPage';
import { useCompanyCustomColor } from '../../common/hooks/useCompanyCustomColors';

export interface AdmissionFormProps {
  company?: Company;
}

export interface AdmissionContainerProps {
  Form: React.FC<AdmissionFormProps>;
  titleKey?: string;
}

export default function AdmissionContainer({
  Form,
  titleKey,
}: AdmissionContainerProps) {
  const { t } = useTranslation();
  const {
    data: company,
    isLoading,
    isError,
  } = useCompaniesControllerFindMeQuery();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));
  const color = useCompanyCustomColor('loginLabelsColor', 'primary.main');
  const welcomeMessageColor = useCompanyCustomColor(
    'loginWelcomeMessageColor',
    'common.white',
  );

  const renderForm = useCallback(
    (children: JSX.Element) => {
      const StackedChildren = (
        <Stack direction="column" spacing={1} width={{ lg: 400 }} color={color}>
          {children}
        </Stack>
      );
      return isMobile ? (
        <RoundedCard sx={{ px: 1, py: 1, width: '95%', maxWidth: 450 }}>
          <CardContent>{StackedChildren}</CardContent>
        </RoundedCard>
      ) : (
        StackedChildren
      );
    },
    [color, isMobile],
  );

  return (
    <Switch>
      <Case condition={isLoading}>
        <LoadingSpinner />
      </Case>
      <Case condition={company?.comingSoonModeIsEnabled}>
        <ComingSoonPage company={company!} />
      </Case>
      <Default>
        <Grid container alignItems="center" height="100vh">
          <Grid item xs={12} lg={6}>
            <Grid
              container
              justifyContent={{ xs: 'center', lg: 'flex-end' }}
              pr={{ xs: 0, lg: 10 }}
            >
              {renderForm(
                <Switch>
                  <Case condition={isError}>
                    <Stack spacing={1}>
                      <Typography variant="h3">
                        {t('login.companyNotFound')}
                      </Typography>
                      <Typography variant="h5">
                        {t('login.companyCheckUrl')}
                      </Typography>
                    </Stack>
                  </Case>
                  <Default>
                    <Unless condition={isNil(titleKey)}>
                      <Avatar
                        src={company?.isologoUrl}
                        alt="company-isologo"
                        style={{ width: 72, height: 72, marginBottom: 30 }}
                      />
                      <Typography
                        variant="h3"
                        sx={{ color }}
                        fontWeight={600}
                        mb={1}
                      >
                        {t(titleKey!)}
                      </Typography>
                    </Unless>
                    <Form company={company} />
                  </Default>
                </Switch>,
              )}
            </Grid>
          </Grid>
          <Grid
            item
            height="100vh"
            lg={6}
            display={{ xs: 'none', lg: 'block' }}
            sx={{
              backgroundImage: `url("${
                company?.loginImageUrl ||
                'https://statics.viralizalo.com/virs/2018/01/VIR_417098_31095_puedes_reconocer_estos_estadios_de_futbol_del_mundo.jpg?cb=2957035'
              }")`,
              backgroundSize: 'cover',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '35%',
                left: '5%',
              }}
            >
              <img
                src={company?.logoUrl}
                alt="company-logo"
                style={{ maxHeight: 300, maxWidth: 350 }}
              />
              <Typography
                variant={'h4'}
                fontWeight="bold"
                color={welcomeMessageColor}
              >
                {t('customization.loginWelcomeMessage', {
                  ns: 'company',
                  defaultValue: '',
                })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Default>
    </Switch>
  );
}
