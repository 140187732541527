import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { delay } from '../../../common/timeUtils';
import {
  selectTriviaCurrentQuestion,
  triviaNextQuestion,
} from './playingTriviaSlice';
import {
  CreateTriviaAnswerDto,
  useTriviaSolutionsControllerAnswerTriviaQuestionMutation,
} from '../../../app/services/futbolProdeApi';

const nextQuestionDelay = 2;

export default function useTriviaPlayHelpers() {
  const { trivia, solution } = useAppSelector((state) => state.playingTrivia);
  const [answerQuestion, answerQuestionResult] =
    useTriviaSolutionsControllerAnswerTriviaQuestionMutation();
  const question = useAppSelector(selectTriviaCurrentQuestion);
  const dispatch = useAppDispatch();

  const goToNextQuestion = async () => {
    await delay(nextQuestionDelay);
    dispatch(triviaNextQuestion());
  };

  return {
    async answerQuestion(
      chosen: CreateTriviaAnswerDto['chosen'],
      remainingSeconds: number,
    ) {
      await answerQuestion({
        triviaId: trivia!.id,
        id: solution!.id,
        createTriviaAnswerDto: {
          question: { id: question!.id },
          chosen,
          elapsedTime: trivia!.maxTimePerQuestion - remainingSeconds,
        },
      }).unwrap();
      await goToNextQuestion();
    },
    answerQuestionResult,
  };
}
