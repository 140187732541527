import { isInStatus, MatchFinalStatuses } from '@futbolprode/ui-common';
import { all, mergeRight, last } from 'ramda';
import { useMemo } from 'react';
import { useAppSelector } from '../../app/hooks';
import {
  Round,
  Tournament,
  useRoundsControllerFindRecentRoundsForQuery,
  useRoundsControllerSummaryQuery,
} from '../../app/services/futbolProdeApi';
import { RoundOrCombinedRound } from '../calendar/RoundsCarousel';

export const isRoundFinished = (round: Round) =>
  round.matches &&
  all((it) => isInStatus(MatchFinalStatuses, it.status), round.matches);

export function useHydrateRoundWithMatches(round: RoundOrCombinedRound) {
  const {
    data: matchesSummary,
    isLoading,
    currentData,
    isFetching,
  } = useRoundsControllerSummaryQuery(round.id.toString());

  const data = useMemo(
    () => mergeRight(round, { matches: matchesSummary ?? [] }),
    [round, matchesSummary],
  ) as Round;

  return { data, isLoading, isFetching, currentData };
}

export function useNoMoreRoundsAvailableQuery(tournament: Tournament) {
  const { data: allRounds, isLoading: areRoundsLoading } =
    useRoundsControllerFindRecentRoundsForQuery();
  const currentRound = useAppSelector(
    (state) => state.summaryNavigation.initialRound,
  );
  const { data: roundWithMatches, isLoading: areMatchesLoading } =
    useHydrateRoundWithMatches(currentRound);
  const data = useMemo(
    () =>
      tournament.showNoMoreRoundsWarning &&
      !areRoundsLoading &&
      !areMatchesLoading &&
      allRounds?.rounds &&
      roundWithMatches &&
      last(allRounds.rounds)!.id === roundWithMatches.id &&
      isRoundFinished(roundWithMatches),
    [
      tournament.showNoMoreRoundsWarning,
      roundWithMatches,
      allRounds,
      areRoundsLoading,
      areMatchesLoading,
    ],
  );
  return { data, isLoading: areRoundsLoading };
}
