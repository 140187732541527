import { Box, Divider, Typography, useTheme } from '@mui/material';
import { NewsSummary } from '../features/news/NewsSummary';
import { useTranslation } from 'react-i18next';
import ScrollToTopFab from './ScrollToTopFab';
import NewsHome from '../features/news/page/NewsHome';
import { AppContainer } from './AppContainer';
import { SectionTitle } from './SectionTitle';

function NewsLayout() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Box
        display={{ xs: 'none', md: 'block' }}
        sx={{
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
        }}
      >
        <AppContainer>
          <>
            <SectionTitle title={t('news.featured')} />
            <NewsSummary padding={0} />
          </>
        </AppContainer>
      </Box>
      <Box
        py={{ xs: 0, md: 4 }}
        sx={{
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
        }}
      >
        <AppContainer>
          <Divider
            sx={{
              display: { xs: 'none', md: 'block' },
              borderColor: theme.palette.primary.main,
              borderWidth: '3px',
              margin: '0 auto',
            }}
          />
        </AppContainer>
      </Box>
      <Box
        py={4}
        sx={{
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
        }}
      >
        <AppContainer>
          <>
            <Typography
              variant="h3"
              textTransform="uppercase"
              sx={{ display: { xs: 'block', md: 'none' }, my: 2 }}
            >
              {t('news.word')}
            </Typography>
            <NewsHome />
            <ScrollToTopFab />
          </>
        </AppContainer>
      </Box>
    </>
  );
}

export default NewsLayout;
