import { isNil } from 'ramda';
import { useCompaniesControllerFindMeQuery } from '../app/services/futbolProdeApi';

export default function TournamentLogo({
  children,
}: {
  children: JSX.Element;
}) {
  const { data: company } = useCompaniesControllerFindMeQuery();
  return isNil(company) || company.disableTournamentDetails ? <></> : children;
}
