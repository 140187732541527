import { Trivia } from '../../app/services/futbolProdeApi';
import { parseISO } from 'date-fns';

export type PlayableStatus = 'comingSoon' | 'started' | 'finished';
export type EnhancedTrivia = Trivia & { playableStatus: PlayableStatus };

export function enhanceTrivia(trivia: Trivia): EnhancedTrivia {
  const playableStatus =
    new Date() > parseISO(trivia.finishesAt)
      ? 'finished'
      : new Date() >= parseISO(trivia.startsAt)
      ? 'started'
      : 'comingSoon';
  return { ...trivia, playableStatus };
}
