import { Chip, ChipProps, Skeleton } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import { chipNoLabelSx, circleChipSx } from '../../../common/MuiExtensions';

export type QuestionStatusType = 'ok' | 'wrong' | 'pending';

export const QuestionStatusChip = ({
  status,
  isLoading,
}: {
  status: QuestionStatusType;
  isLoading: boolean;
}) => {
  const diameter = '24px';
  if (isLoading) {
    return <Skeleton variant="circular" width={diameter} height={diameter} />;
  }

  const icon: JSX.Element | undefined = {
    ok: <Check />,
    wrong: <Clear />,
    pending: undefined,
  }[status];

  const color = {
    ok: 'success',
    wrong: 'error',
    pending: 'default',
  }[status] as ChipProps['color'];

  return (
    <Chip
      sx={{
        ...circleChipSx(diameter),
        ...chipNoLabelSx,
      }}
      size="small"
      icon={icon}
      color={color}
    ></Chip>
  );
};
