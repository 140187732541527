import {
  Box,
  Fab,
  Grow,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function ScrollToTopFab() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const trigger = useScrollTrigger({
    threshold: 100,
    disableHysteresis: true,
  });
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Grow appear={false} in={trigger}>
      <Box
        sx={{
          position: 'fixed',
          bottom: 32,
          right: 32,
          zIndex: 1,
        }}
      >
        <Fab
          size={isMobile ? 'small' : 'large'}
          onClick={scrollToTop}
          color="secondary"
          sx={{ position: 'fixed', bottom: 64, right: 16, borderRadius: 2 }}
        >
          <ArrowUpwardIcon fontSize="large" />
        </Fab>
      </Box>
    </Grow>
  );
}
export default ScrollToTopFab;
