import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { isEmpty, tail, compose, take } from 'ramda';
import {
  News,
  useNewsControllerCombinedNewsQuery,
} from '../../app/services/futbolProdeApi';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import { NewsItemView } from './NewsItemView';
import { Else, If, Then, Unless } from 'react-if';
import NewsCarousel from './NewsCarousel';

const takeWithoutFirst = <T,>(n: number) => compose(take<T>(n), tail<T>);

interface NewsSummaryProps {
  padding?: number;
}

export function NewsSummary({ padding = 0 }: NewsSummaryProps) {
  const { data, isLoading: newsAreLoading } =
    useNewsControllerCombinedNewsQuery();
  const news = useSkeletonReadyList(data, newsAreLoading, 3);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      id="news-summary"
      container
      justifyContent={{ xs: 'center', md: 'space-between' }}
      spacing={{ xs: 0, md: 5 }}
      px={padding}
    >
      <Unless condition={isEmpty(news)}>
        <If condition={isMobile}>
          <Then>
            <NewsCarousel newsList={news} isLoading={newsAreLoading} />
          </Then>
          <Else>
            <Grid item xs={7}>
              <NewsItemView news={news[0]} isLoading={newsAreLoading} />
            </Grid>
            <Grid item xs={5}>
              <Stack direction="column" spacing={3}>
                {takeWithoutFirst<News>(2)(news).map((newsItem) => (
                  <NewsItemView
                    key={newsItem!.id}
                    news={newsItem!}
                    isLoading={newsAreLoading}
                    secondary
                  />
                ))}
              </Stack>
            </Grid>
          </Else>
        </If>
      </Unless>
    </Grid>
  );
}
