import { Formik } from 'formik';
import { Box, Grid, Stack, Alert, TextField } from '@mui/material';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  ProfileSupportEmailDto,
  User,
  useEmailsControllerSendProfileSupportMutation,
} from '../../app/services/futbolProdeApi';
import StyledTextarea from '../../common/StyledTextArea';
import createValidator from 'class-validator-formik';
import { ProfileSupportEmailFormDto } from './ProfileSupportEmailDto';
import { instanceToPlain } from 'class-transformer';
import LoadingButton from '@mui/lab/LoadingButton';

export function PersonalSupportForm({ me }: { me?: User }) {
  const { t } = useTranslation();

  const [sendSupportEmail, result] =
    useEmailsControllerSendProfileSupportMutation();

  const handleSubmit = (input: ProfileSupportEmailDto) => {
    sendSupportEmail(instanceToPlain(input) as any);
  };

  if (!me) return null;

  const supportFormValues: ProfileSupportEmailFormDto =
    new ProfileSupportEmailFormDto(me);

  return (
    <Grid item xs={12} md={8} sx={{ p: 3 }}>
      <Stack spacing={2}>
        {result.isSuccess ? (
          <Alert color="success" sx={{ borderRadius: 3 }} icon={false}>
            {result.isSuccess
              ? t('support.mailSent', {
                  email: supportFormValues.emailToAnswer,
                })
              : t('profile.saveSuccess')}
          </Alert>
        ) : null}
        {me ? (
          <Formik
            initialValues={supportFormValues}
            onSubmit={handleSubmit}
            validate={(input) => {
              if (
                input.emailToAnswer &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                  input.emailToAnswer,
                )
              ) {
                return { emailToAnswer: t('validations.common.isEmail') };
              }
              return createValidator(ProfileSupportEmailFormDto)(input);
            }}
          >
            {({ handleChange, values, errors, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& .MuiTextField-root': {
                          width: '100%',
                          my: 4,
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        value={values.name}
                        name="name"
                        label={t('profile.name')}
                        onChange={handleChange}
                        type="search"
                        variant="filled"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& .MuiTextField-root': {
                          width: '48%',
                        },
                      }}
                    >
                      <TextField
                        value={values.userEmail}
                        name="userEmail"
                        label={t('support.userEmail')}
                        onChange={handleChange}
                        type="search"
                        variant="filled"
                        error={!!errors.userEmail}
                      />
                      <TextField
                        value={values.emailToAnswer}
                        name="emailToAnswer"
                        label={t('support.emailToAnswer')}
                        onChange={handleChange}
                        type="search"
                        variant="filled"
                        error={!!errors.emailToAnswer}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        my: 4,
                      }}
                    >
                      <StyledTextarea
                        name="inquiry"
                        value={values.inquiry}
                        onChange={handleChange}
                        placeholder={t('support.inquiry') as string}
                        sx={{ flexGrow: 1 }}
                        minRows={5}
                      />
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <LoadingButton
                        type="submit"
                        disabled={!isEmpty(errors)}
                        loading={result.isLoading}
                      >
                        {t('common.send')}
                      </LoadingButton>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Formik>
        ) : null}
      </Stack>
    </Grid>
  );
}
