import { Outlet, useLocation } from 'react-router-dom';
import WithAppBar from '../common/WithAppBar';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

export default function AppLayout() {
  const location = useLocation();

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send({ hitType: 'pageview' });
    }
  }, [location]);

  return (
    <WithAppBar>
      <Outlet />
    </WithAppBar>
  );
}
