import { isEmpty } from 'ramda';
import { useCompaniesControllerFindMeWithAreasQuery } from '../../app/services/futbolProdeApi';

export default function useCompanySettingsQuery() {
  const { data: company, isLoading } =
    useCompaniesControllerFindMeWithAreasQuery();

  return {
    isLoading,
    data: {
      company,
      hasAreas: !isLoading && !isEmpty(company?.areas),
      hideNewsFromHome: company?.disableNews || company?.disableNewsFromHome,
    },
  };
}
