import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Award } from '../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { isNilOrEmpty } from '../../common/ramdaExtensions';
import { isNil } from 'ramda';

export default function AwardsSection({ values }: { values: Award[] }) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const visibleAwards = values.filter((it) => !isNilOrEmpty(it.name));
  const itemsPerRow = Math.min(visibleAwards.length, 3);

  return (
    <Grid container justifyContent="center" spacing={5}>
      {visibleAwards.map((it) => (
        <Grid item xs={12} md={12 / itemsPerRow}>
          <Card
            sx={{
              borderBottom: `8px solid ${palette.tertiary.main}`,
              minHeight: 550,
            }}
          >
            <CardMedia
              sx={{ height: 400 }}
              image={it.imageUrl}
              title={it.name}
            />
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography
                variant="h5"
                textTransform="uppercase"
                fontWeight="bold"
                color="tertiary.main"
              >
                {isNil(it.customPosition)
                  ? t(`awardsPage.defaultPosition_${it.position}`)
                  : it.customPosition}
              </Typography>
              <Typography gutterBottom variant="h6" color="tertiary.main">
                {it.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {it.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
