import TriviaSplashScreen from './TriviaSplashScreen';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { isNil } from 'ramda';
import LoadingButton from '@mui/lab/LoadingButton';
import { Trans, useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import ConfirmationModal from '../../../common/ConfirmationModal';
import CelebrationIcon from '@mui/icons-material/Celebration';
import BlockIcon from '@mui/icons-material/Block';
import { Unless } from 'react-if';
import { useTriviaSolutionsControllerDeleteTriviaSolutionMutation } from '../../../app/services/futbolProdeApi';
import { Routes, useCompanyRoutes } from '../../../router';
import { Link } from 'react-router-dom';

export default function PlaySummaryCard() {
  const { appRoute } = useCompanyRoutes();
  const { t } = useTranslation();
  const { trivia, solution } = useAppSelector((state) => state.playingTrivia);
  const [triggerDelete, { isLoading }] =
    useTriviaSolutionsControllerDeleteTriviaSolutionMutation();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const isForfeited = useMemo(
    () => solution?.status === 'forfeited',
    [solution],
  );

  const splashScreenProps = useMemo(
    () =>
      isForfeited
        ? {
            titleKey: 'triviaPage.youLeft',
            subtitleKey: 'triviaPage.forfeitResult',
            icon: BlockIcon,
          }
        : {
            titleKey: 'triviaPage.youFinished',
            subtitleKey: 'triviaPage.yourResult',
            icon: CelebrationIcon,
          },
    [isForfeited],
  );

  const handleClick = () => {
    setShowDeleteConfirmation(true);
  };

  const deleteSolution = async () => {
    if (!isNil(trivia) && !isNil(solution)) {
      await triggerDelete({
        triviaId: trivia.id,
        id: solution.id,
      }).unwrap();
    }
  };

  const BackButton = useMemo(
    () => (
      <Link to={appRoute(Routes.TRIVIAS)}>
        <Button variant="contained">{t('common.goBack')}</Button>
      </Link>
    ),
    [appRoute, t],
  );

  return (
    <TriviaSplashScreen
      {...splashScreenProps}
      actions={
        trivia?.allowReplay && trivia.playableStatus !== 'finished' ? (
          <Stack direction="row" spacing={1}>
            {BackButton}
            <LoadingButton
              loading={isLoading}
              variant="outlined"
              onClick={handleClick}
            >
              {t('triviaPage.playAgain')}
            </LoadingButton>
          </Stack>
        ) : (
          BackButton
        )
      }
    >
      <ConfirmationModal
        descriptionKey="triviaPage.confirmDeleteSolutionDescription"
        open={showDeleteConfirmation}
        onAccept={deleteSolution}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
      <Alert icon={false} severity={isForfeited ? 'error' : 'success'}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="baseline"
          justifyContent="center"
        >
          <Trans
            i18nKey="triviaPage.pointsSummary"
            values={{ points: solution?.points ?? 0 }}
          >
            <Typography>Ganaste</Typography>
            <Typography variant="h5" display="inline">
              {solution?.points}
            </Typography>
            <Typography>puntos</Typography>
          </Trans>
        </Stack>
        <Unless condition={isForfeited}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="baseline"
            justifyContent="center"
          >
            <Trans
              i18nKey="triviaPage.timeSummary"
              values={{ time: solution?.totalElapsedTime ?? 0 }}
            >
              <Typography>Tardaste</Typography>
              <Typography variant="h5" display="inline">
                {solution?.totalElapsedTime}
              </Typography>
              <Typography>segundos en total</Typography>
            </Trans>
          </Stack>
        </Unless>
      </Alert>
    </TriviaSplashScreen>
  );
}
