import { ChangeEvent, Fragment } from 'react';
import { Box, TextField, InputBaseProps } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { useCompanyCustomColor } from '../../../common/hooks/useCompanyCustomColors';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

export interface EmailInputProps {
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  label: string;
  name: string;
  value: string | number;
  onChange: (e: ChangeEvent<any>) => void;
  error: string | undefined;
  InputProps?: InputBaseProps;
  type?: string;
}

export function Input({
  label,
  name,
  value,
  onChange,
  error,
  required,
  ...props
}: EmailInputProps) {
  const { t } = useTranslation();
  const borderColor = useCompanyCustomColor('loginLabelsColor', 'primary.main');
  return (
    <Fragment>
      <Box sx={{ my: 2 }}>
        <InputLabel
          htmlFor={name}
          required={required}
          sx={{
            color: 'inherit',
            marginBottom: 1,
            overflow: 'visible',
            whiteSpace: 'normal',
          }}
        >
          {label}
        </InputLabel>
        <TextField
          id={name}
          fullWidth
          value={value}
          onChange={onChange}
          name={name}
          error={!isNil(error)}
          helperText={error && t(error)}
          inputProps={{ borderColor }}
          sx={{
            color: 'inherit',
            '& label.Mui-focused': {
              color: 'inherit',
              borderColor,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor,
            },
            '& .MuiInputBase-root': {
              borderColor,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                borderColor,
              },
          }}
          {...props}
        />
      </Box>
    </Fragment>
  );
}
