import { StepType } from '@reactour/tour';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { toggleUserProfileMenuOpen } from '../../common/WithAppBar';
import useCompanySettingsQuery from '../../common/hooks/useCompanySettingsQuery';
import { useMediaQuery, useTheme } from '@mui/material';

// Ponemos esto en todos los pasos para que se acomoden cuando terminan de cargar las noticias.
const commonResizeObservables = ['#news-summary'];

const includeWhen = (restriction: boolean | undefined, value: boolean) =>
  restriction ? value : true;

const plainSteps: (StepType & {
  onlyIfNewsVisible?: boolean;
  onlyIfMobile?: boolean;
  onlyIfDesktop?: boolean;
})[] = [
  {
    content: 'tour.steps.welcome',
    // El selector dummy es para que se posicione en el centro como un modal cualquiera.
    selector: '.dummy',
    position: 'center',
  },
  {
    selector: '#news-summary',
    content: 'tour.steps.news',
    onlyIfNewsVisible: true,
  },
  {
    selector: '#summary-navigation',
    content: 'tour.steps.summaryNavigation',
  },
  {
    selector: '.summary-predictions',
    content: 'tour.steps.summaryPredictions',
    position: 'center',
  },
  {
    selector: '#dream-final-panel',
    content: 'tour.steps.dreamFinal',
    position: 'top',
  },
  {
    selector: '#navbar-page-positions',
    content: 'tour.steps.navbarPositions',
    // HACK: Como el último paso usa el menu, lo abrimos acá para estar seguros de que lo va a encontrar.
    action: toggleUserProfileMenuOpen,
    onlyIfDesktop: true,
  },
  {
    selector: '#bottom-navbar-positions',
    content: 'tour.steps.navbarPositions',
    // HACK: Como el último paso usa el menu, lo abrimos acá para estar seguros de que lo va a encontrar.
    action: toggleUserProfileMenuOpen,
    onlyIfMobile: true,
  },
  {
    selector: '#user-profile-menu-content',
    content: 'tour.steps.goodbye',
  },
];

export function useTourSteps() {
  const {
    data: { hideNewsFromHome },
  } = useCompanySettingsQuery();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const steps = useMemo(
    () =>
      plainSteps
        .filter(
          (step) =>
            includeWhen(step.onlyIfNewsVisible, !hideNewsFromHome) &&
            includeWhen(step.onlyIfDesktop, !isMobile) &&
            includeWhen(step.onlyIfMobile, isMobile),
        )
        .map((step) => ({
          ...step,
          content: (
            <Trans
              i18nKey={step.content as string}
              components={{ i: <i />, b: <strong />, p: <p /> }}
            />
          ),
          resizeObservables: (step.resizeObservables ?? []).concat(
            commonResizeObservables,
          ),
        })),
    [hideNewsFromHome, isMobile],
  );

  return steps;
}
