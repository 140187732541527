import { useTranslation } from 'react-i18next';
import {
  User,
  useCompaniesControllerFindMeQuery,
} from '../../app/services/futbolProdeApi';

export default function useRankingHelpers() {
  const { data: company } = useCompaniesControllerFindMeQuery();
  const { t } = useTranslation();

  return {
    userNameForRanking: (user?: User) =>
      company?.disableRankingsUserNames
        ? t('ranking.positions.unknownUser', { id: user?.id })
        : user?.name ?? '',
  };
}
