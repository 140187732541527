import {
  LocalizedIsEmail,
  LocalizedIsNotEmpty,
} from '../../../common/validatorHelpers';

export class ExternalSupportEmailDto {
  constructor() {
    this.name = '';
    this.userEmail = '';
    this.inquiry = '';
  }

  @LocalizedIsNotEmpty()
  name: string;

  @LocalizedIsNotEmpty()
  @LocalizedIsEmail()
  userEmail: string;

  @LocalizedIsNotEmpty()
  inquiry: string;
}
