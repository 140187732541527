import { Link } from 'react-router-dom';
import { NewsDetails } from '../../app/services/futbolProdeApi';
import { useCompanyRoutes } from '../../router';
import { encode } from 'universal-base64url';

interface NewsLinkProps {
  children: JSX.Element | null;
  news: NewsDetails;
}

export default function NewsLink({ children, news }: NewsLinkProps) {
  const { appRoute } = useCompanyRoutes();
  return (
    <Link
      to={
        !news
          ? '#'
          : news.id === undefined
          ? appRoute(
              `news/rss?source=${encode(news.source ?? '')}&externalId=${encode(
                news.externalId ?? '',
              )}`,
            )
          : appRoute(`news/${news.id}`)
      }
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      {children}
    </Link>
  );
}
