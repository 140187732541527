import { Skeleton, Stack, Typography } from '@mui/material';
import Image from 'mui-image';
import { News } from '../../app/services/futbolProdeApi';
import NewsLink from './NewsLink';
import { stripHtml } from '@futbolprode/ui-common';

export function NewsItemView({
  news,
  secondary = false,
  isLoading = false,
}: {
  news: News;
  isLoading?: boolean;
  secondary?: boolean;
}) {
  return (
    <Stack>
      {isLoading ? (
        <Skeleton height={secondary ? '200px' : '400px'} />
      ) : (
        <NewsLink news={news}>
          <Image
            alt="main-news-item"
            src={news.imageUrl!}
            height={secondary ? '200px' : '400px'}
            style={{
              aspectRatio: '1',
              maxHeight: '100%',
              maxWidth: '100%',
              objectPosition: 'top',
              borderRadius: '5px',
            }}
          />
        </NewsLink>
      )}
      <NewsLink news={news}>
        <Typography
          color="secondary.contrastText"
          variant={secondary ? 'h5' : 'h3'}
          sx={{
            ...(secondary
              ? { marginTop: '1rem', fontWeight: '500', fontSize: '1.5rem' }
              : {
                  fontSize: '2.25rem',
                  margin: '1rem 0',
                  paddingRight: '40px',
                  fontWeight: '500',
                }),
          }}
        >
          {isLoading ? <Skeleton /> : news?.title}
        </Typography>
      </NewsLink>
      {!secondary && (
        <Typography
          variant="subtitle1"
          color="secondary.contrastText"
          noWrap
          sx={{ overflow: 'hidden' }}
        >
          {isLoading ? (
            <Skeleton />
          ) : (
            stripHtml(news?.summary ?? news?.description)
          )}
        </Typography>
      )}
    </Stack>
  );
}
