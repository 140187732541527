import { useTranslation } from 'react-i18next';
import {
  useRankingsControllerGlobalRankingQuery,
  Ranking,
} from '../../app/services/futbolProdeApi';
import useRankingHelpers from '../../common/hooks/useRankingHelpers';
import RankingTableSummary from './RankingTableSummary';

export default function GlobalRankingTableSummary() {
  const { t } = useTranslation();
  const { userNameForRanking } = useRankingHelpers();

  return (
    <RankingTableSummary<Ranking>
      title={t('ranking.global')}
      showAllRoute="positions"
      query={useRankingsControllerGlobalRankingQuery}
      itemName={(row) => userNameForRanking(row.user)}
    />
  );
}
