import { Stack, Typography } from '@mui/material';
import { EnhancedTrivia } from './triviaExtensions';
import { TriviaCardSlider } from './TriviaCardSlider';
import { isEmpty, partition } from 'ramda';
import { Unless } from 'react-if';
import FinishedTriviasSection from './FinishedTriviasSection';
import { useTranslation } from 'react-i18next';

export interface TriviaShowcaseSectionProps {
  items: EnhancedTrivia[];
  isLoading: boolean;
}

export default function TriviaShowcaseSection({
  items,
  isLoading,
}: TriviaShowcaseSectionProps) {
  const { t } = useTranslation();
  const [finished, rest] = partition(
    (it) => it.playableStatus === 'finished',
    items,
  );

  return (
    <Stack pb={5} spacing={2}>
      <TriviaCardSlider
        items={isLoading ? items : rest}
        isLoading={isLoading}
      />
      <Unless condition={isLoading || isEmpty(finished)}>
        <Typography variant="h4">{t('triviaPage.finishedTrivias')}</Typography>
        <FinishedTriviasSection items={finished} />
      </Unless>
    </Stack>
  );
}
