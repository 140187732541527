import { useTheme } from '@mui/material';
import { ProviderProps, TourProvider } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { TourNavigation } from './TourNavigation';
import { useUsersControllerFindMeQuery } from '../../app/services/futbolProdeApi';
import { useTourSteps } from './TourSteps';

export default function AppTourProvider({
  children,
}: {
  children: ProviderProps['children'];
}) {
  const { data: user } = useUsersControllerFindMeQuery();
  const theme = useTheme();
  const steps = useTourSteps();
  const styles: ProviderProps['styles'] = {
    popover: (base) => ({ ...base, textAlign: 'justify' }),
    badge: (base) => ({
      ...base,
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.tertiary.contrastText,
    }),
  };
  const body = document.getElementsByTagName('body')[0];
  const disableScroll = () => {
    disableBodyScroll(body);
  };
  const enableScroll = () => {
    enableBodyScroll(body);
  };
  const isFirstTime = user?.showInitialTour ?? true;

  return (
    <TourProvider
      badgeContent={({ totalSteps, currentStep }) =>
        `${currentStep + 1}/${totalSteps}`
      }
      afterOpen={disableScroll}
      beforeClose={enableScroll}
      showCloseButton={false}
      components={{ Navigation: TourNavigation }}
      onClickMask={({ setIsOpen }) => {
        if (!isFirstTime) {
          setIsOpen(false);
        }
      }}
      styles={styles}
      disableKeyboardNavigation={isFirstTime ? ['esc'] : false}
      steps={steps}
    >
      <style>{`:root { --reactour-accent: ${theme.palette.tertiary.main}; }`}</style>
      {children}
    </TourProvider>
  );
}
