import { Alert, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Case, Switch } from 'react-if';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthControllerVerifyEmailMutation } from '../../../app/services/futbolProdeApi';
import { Routes, useCompanyRoutes } from '../../../router';
import { AdmissionFormProps } from '../AdmissionContainer';

export function VerifyEmailPage({ company: _company }: AdmissionFormProps) {
  const [verifyEmail, result] = useAuthControllerVerifyEmailMutation();
  const [searchParams] = useSearchParams();
  const payload = searchParams.get('payload');
  const [alreadySent, setAlreadySent] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rootRoute } = useCompanyRoutes();

  useEffect(() => {
    if (payload && !alreadySent) {
      setAlreadySent(true);
      verifyEmail(payload!)
        .unwrap()
        .then(() => {
          setTimeout(() => {
            navigate(rootRoute(Routes.LOGIN));
          }, 3000);
        });
    }
  }, [alreadySent, payload, verifyEmail, rootRoute, navigate]);

  return (
    <Switch>
      <Case condition={result.isLoading}>
        <LinearProgress />
        <Typography>{t('verifyPage.verifying')}</Typography>
      </Case>
      <Case condition={result.isSuccess}>
        <LinearProgress />
        <Alert severity="success">
          <Typography>{t('verifyPage.success')}</Typography>
        </Alert>
      </Case>
      <Case condition={result.isError}>
        <Alert severity="error">
          <Typography>{t('verifyPage.error')}</Typography>
        </Alert>
      </Case>
    </Switch>
  );
}
