import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectActiveRanking,
  setMode,
  setPagination,
  setRoundId,
} from '../activeRankingSlice';
import { isEmpty, isNil } from 'ramda';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo, useEffect } from 'react';
import {
  ActiveButton,
  OverlappingButtons,
} from '../../../common/OverlappingButtons';
import RoundsCarousel, {
  RoundOrCombinedRound,
} from '../../calendar/RoundsCarousel';
import {
  AreaRanking,
  CombinedRoundsRankingDefinition,
  Ranking,
  Round,
  Tournament,
  useTournamentsControllerGetSummaryQuery,
} from '../../../app/services/futbolProdeApi';
import { Nullable } from 'typescript-nullable';
import SearchTextField from '../../../common/rankings/SearchTextInput';
import RankingPagination from '../../../common/rankings/RankingPagination';
import RankingDataGrid from '../../../common/rankings/RankingDataGrid';

interface RankingTableProps {
  isGlobalRanking: boolean;
  selectedTournament?: Tournament;
  recentRounds?: RoundOrCombinedRound[];
  recentRoundsAreLoading?: boolean;
  currentRound?: Nullable<Round | CombinedRoundsRankingDefinition>;
  columns: any;
  activeRanking?: AreaRanking[] | Ranking[];
  activeCount?: number;
  activePage?: number;
  currentAreaLevel?: number;
  isRankingLoading: boolean;
  searchTerm?: string;
  setSearchTerm?: (value: string) => void;
}

export function RankingTable({
  isGlobalRanking,
  selectedTournament,
  recentRounds,
  recentRoundsAreLoading,
  currentRound,
  columns,
  activeRanking,
  activeCount,
  activePage,
  currentAreaLevel,
  isRankingLoading,
  searchTerm,
  setSearchTerm,
}: RankingTableProps) {
  const dispatch = useAppDispatch();
  const { mode, take, roundId } = useAppSelector(selectActiveRanking);
  const { t } = useTranslation();

  const { data: summary } = useTournamentsControllerGetSummaryQuery();

  const activeButton = useMemo(
    () => (mode === 'global' ? 'left' : 'right'),
    [mode],
  );

  // Reinicia paginación cuando cambia el search
  useEffect(() => {
    dispatch(setPagination({ skip: 0, take: 20 }));
  }, [dispatch, searchTerm]);

  const shouldLoadRounds =
    mode === 'rounds' &&
    !isNil(roundId) &&
    !isNil(recentRounds) &&
    !recentRoundsAreLoading;

  const handleSetActiveButton = (activeButton: ActiveButton) => {
    dispatch(setMode({ mode: activeButton === 'left' ? 'global' : 'rounds' }));
    dispatch(setRoundId({ roundId: currentRound?.id }));
  };

  const Paginator = ({ sx }: { sx?: SxProps<Theme> }) => (
    <RankingPagination
      disabled={isEmpty(activeRanking)}
      count={activeCount && activePage ? Math.ceil(activeCount / take) : 1}
      page={activePage}
      onChange={(event: React.ChangeEvent<unknown>, value: number) =>
        dispatch(setPagination({ skip: (value - 1) * 20, take: 20 }))
      }
      sx={sx}
    />
  );

  return (
    <Stack spacing={{ xs: 2, md: 8 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: '20px', md: '0px' }}
      >
        <OverlappingButtons
          disabled={recentRoundsAreLoading ?? true}
          activeButton={activeButton}
          setActiveButton={handleSetActiveButton}
          visible={!isGlobalRanking}
          leftText="ranking.positions.pluralGeneral"
          rightText="ranking.positions.byRound"
        />
        {isNil(setSearchTerm) ? null : (
          <SearchTextField
            label={
              isNil(currentAreaLevel)
                ? t('ranking.positions.searchUsers')
                : t('ranking.positions.searchBy', {
                    criteria: t('user.areaLevel1', { ns: 'company' }),
                  })
            }
            value={searchTerm}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(event.target.value);
            }}
          />
        )}
        <Paginator sx={{ alignSelf: 'flex-end' }} />
      </Box>
      <Box sx={{ display: shouldLoadRounds ? 'flex' : 'none' }}>
        {shouldLoadRounds && !isGlobalRanking ? (
          <RoundsCarousel
            recentRounds={recentRounds}
            isLoading={recentRoundsAreLoading ?? true}
            defaultTournamentId={selectedTournament?.id}
            summary={summary?.data}
            onRoundClick={(round) =>
              dispatch(setRoundId({ roundId: round.id }))
            }
            currentRound={
              recentRounds.find((it) => it.id === roundId) ?? recentRounds?.[0]
            }
            hideTournamentName
          />
        ) : null}
      </Box>
      <Box>
        <RankingDataGrid
          rows={(activeRanking ?? []) as any}
          getRowId={(row) => row.id ?? row.areaId}
          columns={columns}
          loading={isRankingLoading}
        />
        <Paginator sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} />
      </Box>
    </Stack>
  );
}
