import { Typography, Stack, Skeleton } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { selectTriviaCurrentQuestion } from './playingTriviaSlice';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import { TriviaQuestionChoice } from '../../../app/services/futbolProdeApi';
import { QuestionChoiceButton } from './QuestionChoiceButton';
import useTriviaPlayHelpers from './useTriviaPlayHelpers';

export default function PlayCardQuestion({
  isLoading,
  remainingSeconds,
  onQuestionAnswered,
}: {
  isLoading: boolean;
  remainingSeconds: number;
  onQuestionAnswered: () => void;
}) {
  const question = useAppSelector(selectTriviaCurrentQuestion);
  const { disableAnswerSubmit } = useAppSelector(
    (state) => state.playingTrivia,
  );
  const choices = useSkeletonReadyList(question?.choices, isLoading, 4);
  const { answerQuestion, answerQuestionResult } = useTriviaPlayHelpers();
  const {
    isLoading: isSubmitting,
    data: response,
    originalArgs: submitArgs,
  } = answerQuestionResult;

  const handleClick = async (choice: TriviaQuestionChoice) => {
    onQuestionAnswered();
    await answerQuestion({ id: choice.id }, remainingSeconds);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h5">
        {isLoading ? <Skeleton width={100} /> : question?.question}
      </Typography>
      <Stack spacing={1}>
        {choices.map((it) => {
          const isChosen = !isLoading && response?.chosen?.id === it.id;
          return (
            <QuestionChoiceButton
              variant={isChosen ? 'contained' : 'outlined'}
              color={
                isChosen
                  ? response?.chosen?.isCorrect
                    ? 'success'
                    : 'error'
                  : 'inherit'
              }
              onClick={() => handleClick(it)}
              key={it.id}
              isDisabled={disableAnswerSubmit}
              loading={
                isSubmitting &&
                submitArgs?.createTriviaAnswerDto.chosen?.id === it.id
              }
            >
              <Typography variant="body1">
                {isLoading ? <Skeleton width={150} /> : it.option}
              </Typography>
            </QuestionChoiceButton>
          );
        })}
      </Stack>
    </Stack>
  );
}
