import { IsOptional, ValidateIf } from 'class-validator';
import {
  LocalizedIsEmail,
  LocalizedIsNotEmpty,
} from '../../../common/validatorHelpers';
import { isEmpty } from 'ramda';

class RegisterUserDto {
  constructor() {
    this.name = '';
    this.password = '';
    this.externalId = '';
  }

  @LocalizedIsNotEmpty()
  name: string;

  @LocalizedIsNotEmpty()
  password: string;

  externalId?: string;
  extraFields?: ExtraField[];
}

export class SignUpUserDto extends RegisterUserDto {
  constructor() {
    super();
    this.email = '';
  }

  @LocalizedIsEmail()
  email: string;
}

export class ActivateUserDto extends RegisterUserDto {
  constructor() {
    super();
    this.email = '';
  }

  @LocalizedIsEmail()
  @IsOptional()
  @ValidateIf((o) => !isEmpty(o.email))
  email: string;
}

export class ExtraField {
  constructor() {
    this.name = '';
  }
  @LocalizedIsNotEmpty()
  name: string;
  value?: boolean | string;
}
