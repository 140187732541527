import { useMediaQuery, useTheme } from '@mui/material';
import { Banner as BannerType } from '../../app/services/futbolProdeApi';
import { isEmpty, isNil } from 'ramda';

interface BannerProps {
  banner?: BannerType;
  style: React.CSSProperties;
  linkStyle?: React.CSSProperties;
}

export function Banner({ banner, style, linkStyle }: BannerProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const imageToUse = isMobile
    ? banner?.mobileImageUrl
    : banner?.desktopImageUrl;

  if (isNil(banner) || isEmpty(banner) || isNil(imageToUse)) return null;
  const bannerImage = <img src={imageToUse} alt="banner" style={style} />;
  if (!banner?.link) return bannerImage;
  return (
    <a
      href={banner?.link ?? '#'}
      target="_blank"
      rel="noopener noreferrer"
      style={linkStyle}
    >
      {bannerImage}
    </a>
  );
}
