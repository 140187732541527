import { Grid } from '@mui/material';
import { EnhancedTrivia } from './triviaExtensions';
import TriviaSelectorCard from './TriviaSelectorCard';

export default function FinishedTriviasSection({
  items,
}: {
  items: EnhancedTrivia[];
}) {
  return (
    <Grid container justifyContent="space-around" rowGap={2}>
      {items.map((it) => (
        <Grid item xs={12} sm={6} md={5.9} key={it.id}>
          <TriviaSelectorCard value={it} isLoading={false} small />
        </Grid>
      ))}
    </Grid>
  );
}
