import { Button, ButtonGroup, ButtonGroupProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function OverlappingButtons({
  disabled = false,
  visible = true,
  leftText,
  rightText,
  activeButton,
  setActiveButton,
  ...props
}: ButtonGroupProps & {
  disabled?: boolean;
  visible?: boolean;
  leftText: string;
  rightText: string;
  activeButton: ActiveButton;
  setActiveButton: (it: ActiveButton) => void;
}) {
  const { t } = useTranslation();

  const OvButton = ({
    kind,
    translationKey,
  }: {
    kind: ActiveButton;
    translationKey: string;
  }) => (
    <Button
      variant={activeButton === kind ? 'contained' : 'outlined'}
      onClick={() => setActiveButton(kind)}
    >
      {t(translationKey)}
    </Button>
  );

  return (
    <ButtonGroup
      disableElevation
      variant="contained"
      disabled={disabled}
      sx={{ visibility: visible ? 'visible' : 'hidden' }}
      {...props}
    >
      <OvButton kind="left" translationKey={leftText} />
      <OvButton kind="right" translationKey={rightText} />
    </ButtonGroup>
  );
}

export type ActiveButton = 'left' | 'right';
