import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
// @ts-ignore
import SwipeableViews from 'react-swipeable-views-react-18-fix';
// @ts-ignore
import { autoPlay } from 'react-swipeable-views-utils';
import { NewsDetails } from '../../app/services/futbolProdeApi';
import { take } from 'ramda';
import NewsLink from './NewsLink';
import Skeleton from '@mui/material/Skeleton';
import { stripHtml } from '@futbolprode/ui-common';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const MAX_NEWS_TO_SHOW = 5;

export default function NewsCarousel({
  newsList,
  isLoading,
}: {
  newsList: NewsDetails[];
  isLoading: boolean;
}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = MAX_NEWS_TO_SHOW;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const activeNews = newsList[activeStep];

  const Stepper = (
    <MobileStepper
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      nextButton={<div />}
      backButton={<div />}
      sx={{
        backgroundColor: 'transparent',
        '& .MuiMobileStepper-dot': {
          backgroundColor: 'secondary.contrastText',
        },
        '& .MuiMobileStepper-dotActive': {
          backgroundColor: 'primary.main',
        },
      }}
    />
  );

  return isLoading ? (
    <>
      <Skeleton
        width="350px"
        height="350px"
        sx={{ backgroundColor: 'text.secondary' }}
      />
      <Skeleton
        width="100%"
        height="50px"
        sx={{ backgroundColor: 'text.secondary' }}
      />
      {Stepper}
    </>
  ) : (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        interval={5000}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {take(MAX_NEWS_TO_SHOW)(newsList).map((news, index) => (
          <NewsLink key={news.title} news={news}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  height: '350px',
                  maxHeight: '100%',
                  maxWidth: '100%',
                  objectFit: 'cover',
                  aspectRatio: '1',
                  margin: '0 auto',
                }}
                src={news.imageUrl}
                alt={news.title}
              />
            ) : null}
          </NewsLink>
        ))}
      </AutoPlaySwipeableViews>
      <NewsLink news={activeNews}>
        <Typography
          my={2}
          variant="h5"
          sx={{ height: '100px', paddingBottom: '1rem' }}
        >
          {activeNews.title}
        </Typography>
      </NewsLink>
      <Typography my={2} variant="body1" height="3rem" overflow="hidden">
        {stripHtml(activeNews.summary ?? activeNews.description)}
      </Typography>
      {Stepper}
    </Box>
  );
}
