import { Formik } from 'formik';
import {
  Box,
  Grid,
  Stack,
  Alert,
  TextField,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  InputAdornment,
} from '@mui/material';
import { clone, isEmpty, isNil, omit } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  User,
  useCompaniesControllerFindMeQuery,
  useUsersControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';
import { EditOff } from '@mui/icons-material';
import { When } from 'react-if';
import { availableLanguages } from '../../App';

const disabledProps = (disabled: boolean, t: (key: string) => any) =>
  disabled
    ? {
        disabled: true,
        helperText: t('profile.onlyAdminCanEdit'),
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <EditOff />
            </InputAdornment>
          ),
        },
      }
    : {};

export function PersonalDataForm({ me }: { me?: User }) {
  const { t } = useTranslation();

  const { data: company } = useCompaniesControllerFindMeQuery();
  const [updateUser, result] = useUsersControllerUpdateMutation();

  const [mailChanged, setMailChanged] = useState(false);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(
    undefined,
  );

  const showDisabledEditAlert =
    !company?.allowUserEmailEdit || !company?.allowUserNameEdit;

  const handleSubmit = (input: User) => {
    const updateUserDto = {} as any;
    (
      ['name', 'email', 'preferredLanguage', 'notificationsEnabled'] as any
    ).forEach(
      (
        field: 'name' | 'email' | 'preferredLanguage' | 'notificationsEnabled',
      ) => {
        if (
          !isNil(input[field]) &&
          input[field] !== '' &&
          input[field] !== me?.[field]
        ) {
          updateUserDto[field] = input[field];
        }
      },
    );
    if (password && password === confirmPassword) {
      updateUserDto.password = password;
    }
    const fieldsToOmit = [];
    if (!company?.allowUserEmailEdit) {
      fieldsToOmit.push('email');
    }
    if (!company?.allowUserNameEdit) {
      fieldsToOmit.push('name');
    }
    const finalUpdateDto = omit(fieldsToOmit, updateUserDto);
    updateUser({
      id: me!.id,
      updateUserDto: finalUpdateDto,
    });
  };
  return (
    <Grid item xs={12} md={8} sx={{ p: 3 }}>
      <Stack spacing={2}>
        <When condition={showDisabledEditAlert}>
          <Alert color="warning" sx={{ borderRadius: 3 }} icon={false}>
            {t('profile.adminAlert')}
          </Alert>
        </When>
        {result.isSuccess ? (
          <Alert color="success" sx={{ borderRadius: 3 }} icon={false}>
            {mailChanged
              ? t('profile.mailSent', {
                  email: result.data?.email,
                })
              : t('profile.saveSuccess')}
          </Alert>
        ) : null}
        {me && company ? (
          <Formik
            initialValues={clone(me)}
            onSubmit={handleSubmit}
            validate={(input) => {
              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input.email)
              ) {
                return { email: t('validations.common.isEmail') };
              }
              return {};
            }}
          >
            {({ handleChange, values, errors, handleSubmit }) => {
              if (result.isUninitialized) {
                setMailChanged(values.email !== me.email);
              }
              return (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Typography variant="h5">
                      {t('profile.personalData')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& .MuiTextField-root': {
                          width: '100%',
                          my: 4,
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        value={values.name}
                        name="name"
                        label={t('profile.name')}
                        onChange={handleChange}
                        type="search"
                        variant="filled"
                        {...disabledProps(!company?.allowUserNameEdit, t)}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& .MuiTextField-root': {
                          width: '48%',
                        },
                      }}
                    >
                      <Select
                        name="preferredLanguage"
                        value={values.preferredLanguage}
                        onChange={handleChange}
                        sx={{ width: '48%' }}
                      >
                        {availableLanguages.map((code) => (
                          <MenuItem key={code} value={code}>
                            {t(`common.languages.${code}`)}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        value={values.email}
                        name="email"
                        label={t('profile.email')}
                        onChange={handleChange}
                        type="search"
                        variant="filled"
                        {...disabledProps(!company?.allowUserEmailEdit, t)}
                        error={!!errors.email}
                      />
                    </Box>
                    <Box
                      mt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& .MuiTextField-root': {
                          width: '48%',
                          my: 4,
                        },
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`notificationsEnabled`}
                            name={`notificationsEnabled`}
                            checked={values.notificationsEnabled}
                            onChange={handleChange}
                          />
                        }
                        label={t(`profile.notificationsEnabled`)}
                      />
                    </Box>

                    <Typography variant="h5" mt={2}>
                      {t('profile.changePassword')}
                      <Typography variant="subtitle1" color="textSecondary">
                        {t('profile.changePasswordExplain')}
                      </Typography>
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& .MuiTextField-root': {
                          width: '48%',
                          my: 3,
                        },
                      }}
                    >
                      <TextField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        label={t('profile.newPassword')}
                        autoComplete="new-password"
                        type="password"
                        variant="filled"
                      />
                      <TextField
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        label={t('profile.repeatPassword')}
                        autoComplete="new-password"
                        type="password"
                        variant="filled"
                        error={!!password && password !== confirmPassword}
                      />
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        type="submit"
                        disabled={
                          !isEmpty(errors) || password !== confirmPassword
                        }
                      >
                        {t('profile.save')}
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Formik>
        ) : null}
      </Stack>
    </Grid>
  );
}
