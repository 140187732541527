//TODO: mover todo esto a ui-commons
import { parse } from 'tldts';
import axios from 'axios';
import { useSelector } from 'react-redux';

const headerDomain = process.env.REACT_APP_HEADER_DOMAIN;
const isDevelopment = process.env.NODE_ENV === 'development';

export function domain() {
  const { domain } =
    typeof window === 'undefined'
      ? { domain: 'localhost' }
      : parse(window.location.href);
  if (isDevelopment) {
    return headerDomain ?? 'localhost';
  } else {
    return domain!;
  }
}

export function basePath() {
  return typeof window === 'undefined'
    ? ''
    : window.location.pathname.split('/')[1];
}

export function useDownloadFile(options: {
  url: string;
  data?: any;
  method?: string;
}) {
  const accessToken = useSelector((state) => (state as any).auth?.accessToken);

  return {
    downloadFile: () =>
      axios({
        url: `${process.env.REACT_APP_API_URL || 'http://localhost:4000'}/${
          options.url
        }`,
        method: 'POST',
        data: options.data,
        headers: {
          'x-futbolprode-host': domain(),
          'x-futbolprode-path': basePath(),
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob', // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'download.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }),
  };
}
