import { Container, ContainerProps } from '@mui/material';

export const AppContainer = ({
  children,
  sx,
}: {
  children: JSX.Element | JSX.Element[];
  sx?: ContainerProps['sx'];
}) => (
  <Container maxWidth="xl" sx={sx}>
    {children}
  </Container>
);
