import { useNavigate, useSearchParams } from 'react-router-dom';
import { memo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Grid, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { SignUpUserDto } from './SignUpUserDto';
import { useAuthControllerFinishRecoverPasswordMutation } from '../../../app/services/futbolProdeApi';
import { Routes, useCompanyRoutes } from '../../../router';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { PasswordField } from './PasswordField';

function FinishRecoverForm() {
  const { t } = useTranslation();
  const { rootRoute } = useCompanyRoutes();
  const theme = useTheme();
  const formik: any = useRef(null);

  const [invitationParams] = useSearchParams();

  const jwt = invitationParams.get('payload');
  const navigate = useNavigate();
  const [finishRecover, finishRecoverResult] =
    useAuthControllerFinishRecoverPasswordMutation();

  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);

  const user = new SignUpUserDto();

  const handleValidation = (input: any) => {
    if (!repeatedPassword || !input.password) {
      return { password: t('activation.emptyPasswords') };
    }
    if (repeatedPassword !== input.password) {
      return { password: t('activation.passwordsNotMatching') };
    }
  };

  const handleSubmit = async (values: SignUpUserDto) => {
    try {
      if (!isNil(jwt)) {
        await finishRecover({
          recoverPasswordToken: jwt,
          password: values.password,
        }).unwrap();
        navigate(rootRoute(Routes.LOGIN));
      }
    } catch (e) {
      if (formik.current) {
        const errorMessage = (e as any)?.data?.message;
        formik.current!.setErrors({
          password:
            errorMessage === 'password_recovery_not_requested' ||
            errorMessage === 'password_recovery_invalid_token'
              ? t('activation.passwordRecoveryNotRequested')
              : t('activation.errorRecovering'),
        });
      }
    }
  };

  return (
    <Formik
      innerRef={formik}
      initialValues={user}
      onSubmit={handleSubmit}
      validate={handleValidation}
      validateOnChange={false}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <PasswordField
              name="password"
              errors={''}
              placeholder={t('login.newPassword')}
              value={values.password}
              onChange={handleChange}
              visibilityHook={[showPassword, setShowPassword]}
            />
            <PasswordField
              name="repeatPassword"
              errors={errors.password}
              placeholder={t('login.repeatPassword')}
              value={repeatedPassword}
              onChange={(e: any) => setRepeatedPassword(e.target.value)}
              visibilityHook={[showRepeatedPassword, setShowRepeatedPassword]}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              loading={finishRecoverResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                fontWeight: 600,
                textTransform: 'capitalize',
                marginTop: 4,
              }}
            >
              {t('activation.changePassword')}
            </LoadingButton>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default memo(FinishRecoverForm);
