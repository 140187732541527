import {
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import { MatchDate } from '../../app/services/futbolProdeApi';
import { useMediaQueryHelpers } from '../../common/hooks/useMediaQueryHelpers';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { clamp, findIndex, head, includes, last, pipe, propEq } from 'ramda';
import { useEffect, useState } from 'react';
import { useI18nHelpers } from '@futbolprode/ui-common';
import { parseISO } from 'date-fns';
import { useAppSelector } from '../../app/hooks';

function RoundInfo({
  round,
  idx,
  currentBreakpoint,
  onRoundClick,
  currentRound,
}: {
  round: MatchDate;
  idx: number;
  currentBreakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onRoundClick: (round: MatchDate) => void;
  currentRound: MatchDate;
}) {
  const isCurrentRound = currentRound.id === round.id;
  const { formatDate } = useI18nHelpers();

  const Text = (props: TypographyProps) => (
    <Typography
      color={isCurrentRound ? 'textPrimary' : 'textSecondary'}
      fontWeight={isCurrentRound ? 500 : 300}
      variant={currentBreakpoint === 'xs' ? 'caption' : 'body1'}
      {...props}
    ></Typography>
  );

  return (
    <>
      {idx === 0 ? null : (
        <Divider orientation="vertical" variant="middle" flexItem>
          <Typography color="textSecondary">|</Typography>
        </Divider>
      )}
      <Grid
        item
        xs
        sx={{ cursor: 'pointer' }}
        onClick={() => onRoundClick(round)}
      >
        <Stack textAlign="center" direction="column">
          <Text textTransform="uppercase">
            {formatDate('iii')(parseISO(round.date))}
          </Text>
          <Text>{formatDate('d MMM')(parseISO(round.date))}</Text>
        </Stack>
      </Grid>
    </>
  );
}

interface DatesCarrouselProps {
  recentRounds?: MatchDate[];
  isLoading: boolean;
  onRoundClick: (round: MatchDate) => void;
}

export default function DatesCarousel({
  isLoading,
  recentRounds,
  onRoundClick,
}: DatesCarrouselProps) {
  const { date: currentRound, initialDate } = useAppSelector(
    (state) => state.summaryNavigation,
  );
  const currentBreakpoint = useMediaQueryHelpers();
  const breakpointToRoundsToShow = {
    xs: 3,
    sm: 5,
    md: 6,
    lg: 8,
    xl: 8,
  };
  const numberOfRoundsToShow: number =
    breakpointToRoundsToShow[currentBreakpoint];
  const [initialCurrentRound, setInitialCurrentRound] = useState(currentRound);

  useEffect(() => {
    setInitialCurrentRound((prev) =>
      prev?.id !== undefined ? prev : currentRound,
    );
  }, [currentRound]);

  const [userBias, setUserBias] = useState(0);
  // given the current round, show me its "neighbors" by default (closest rounds in the array, because of date order)
  // user bias is how much the user has clicked (the arrows change which rounds to show)
  const [roundsToShow, setRoundsToShow] = useState<MatchDate[]>([]);

  useEffect(() => {
    if (currentRound.id === initialDate.id) {
      setUserBias(0);
    }
  }, [currentRound, initialDate]);

  useEffect(() => {
    const defaultCurrentRoundPositionInGlobalRounds = pipe(
      findIndex(propEq('id', initialCurrentRound.id)),
    )(recentRounds ?? []);
    const startingRound =
      clamp(
        0,
        recentRounds?.length,
        defaultCurrentRoundPositionInGlobalRounds + userBias,
      ) ?? 0;
    setRoundsToShow(
      recentRounds?.slice(
        Math.max(startingRound - 1, 0),
        startingRound + numberOfRoundsToShow - 1,
      ) ?? [],
    );
  }, [recentRounds, initialCurrentRound, numberOfRoundsToShow, userBias]);

  return isLoading ||
    recentRounds === undefined ||
    currentRound === undefined ? (
    <Skeleton width="100%" />
  ) : (
    <>
      <Grid container alignItems="center">
        <KeyboardArrowLeftIcon
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            setUserBias((prev) =>
              includes(head(recentRounds), roundsToShow)
                ? prev
                : prev - numberOfRoundsToShow,
            )
          }
        />
        {roundsToShow.map((it, idx) => (
          <RoundInfo
            key={it.id}
            currentBreakpoint={currentBreakpoint}
            round={it}
            idx={idx}
            onRoundClick={onRoundClick}
            currentRound={currentRound}
          />
        ))}
        <KeyboardArrowRightIcon
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            setUserBias((prev) =>
              includes(last(recentRounds), roundsToShow)
                ? prev
                : prev + numberOfRoundsToShow,
            )
          }
        />
      </Grid>
    </>
  );
}
