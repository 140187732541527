import { useAwardsControllerFindAllQuery } from '../../app/services/futbolProdeApi';
import SplitLayout from '../../layouts/SplitLayout';
import { useTranslation } from 'react-i18next';
import AwardsSection from './AwardsSection';
import { partition, isNil } from 'ramda';
import { Stack } from '@mui/material';

export default function AwardsPage() {
  const { t } = useTranslation();
  const { data } = useAwardsControllerFindAllQuery();
  const [defaultAwards, customAwards] = partition(
    (it) => isNil(it.customPosition),
    data ?? [],
  );
  return (
    <SplitLayout
      title={t('awardsPage.title')}
      HeaderContent={<></>}
      MainContent={
        <Stack spacing={2} alignItems="flex-end">
          <AwardsSection values={defaultAwards ?? []} />
          <AwardsSection values={customAwards ?? []} />
        </Stack>
      }
    />
  );
}
