import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const activeRankingSlice = createSlice({
  name: 'activeRanking',
  initialState: {
    tabIndex: 0 as number,
    roundId: undefined as number | undefined,
    skip: 0 as number,
    take: 20 as number,
    mode: 'global' as 'global' | 'rounds',
  },
  reducers: {
    setMode(state, action) {
      state.mode = action.payload.mode ?? 'global';
      state.skip = 0;
      state.take = 20;
    },
    setTabIndex(state, action) {
      state.tabIndex = action.payload.tabIndex ?? 0;
      state.skip = 0;
      state.take = 20;
    },
    setRoundId(state, action) {
      state.roundId = action.payload.roundId;
    },
    setPagination(state, action) {
      state.skip = action.payload.skip ?? 0;
      state.take = action.payload.take ?? 20;
    },
    setInitialPagination(state, _action) {
      state.skip = 0;
      state.take = 20;
    },
  },
});

export const selectActiveRanking = (state: RootState) => state.activeRanking;

const { actions, reducer } = activeRankingSlice;
export const { setMode, setTabIndex, setRoundId, setPagination } = actions;
export default reducer;
