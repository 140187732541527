import React from 'react';
import { StyledTabs, StyledTab } from './rankingTabs';
import { Tournament } from '../../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import {
  selectActiveRanking,
  setMode,
  setTabIndex,
} from '../activeRankingSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useCompanyTranslations } from '@futbolprode/ui-common';

interface RankingTabsProps {
  tournaments: Tournament[];
}

const RankingsTabs = ({ tournaments }: RankingTabsProps) => {
  const dispatch = useAppDispatch();
  const { tabIndex } = useAppSelector(selectActiveRanking);
  const { t } = useTranslation();
  const { tournamentName } = useCompanyTranslations();
  const handleOnChange = (
    e: React.SyntheticEvent<Element, Event>,
    index: number,
  ) => {
    dispatch(setMode({ mode: 'global' }));
    dispatch(setTabIndex({ tabIndex: index }));
  };

  return (
    <StyledTabs variant="scrollable" value={tabIndex} onChange={handleOnChange}>
      <StyledTab label={t('ranking.positions.global')} />
      {tournaments.map((it) => (
        <StyledTab key={it.name} label={tournamentName(it)} />
      ))}
    </StyledTabs>
  );
};

export default RankingsTabs;
