import { Pagination, PaginationProps } from '@mui/material';

export default function RankingPagination({
  disabled,
  count,
  page,
  onChange,
  sx,
}: PaginationProps) {
  return (
    <Pagination
      showLastButton
      variant="outlined"
      disabled={disabled}
      count={count}
      key={page}
      page={page}
      onChange={onChange}
      sx={{
        ...sx,
        '& .MuiPaginationItem-page': {
          border: 'none',
        },
      }}
    />
  );
}
