import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import LoadingSpinner from './common/LoadingSpinner';
import App from './App';
import { initializeI18n } from '@futbolprode/ui-common';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { enhanceEndpoints } from './app/services/baseApi';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

initializeI18n();
enhanceEndpoints();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <GoogleOAuthProvider clientId="1044058523099-7k9oapqq74unvhk385quiusa0phad344.apps.googleusercontent.com">
    <React.StrictMode>
      <Suspense fallback={<LoadingSpinner />}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    </React.StrictMode>
  </GoogleOAuthProvider>,
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        const target = event.target ?? {};
        if ('state' in target && target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
