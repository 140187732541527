import { useTranslation } from 'react-i18next';
import {
  AreaRanking,
  useRankingsControllerGlobalRankingByAreaQuery,
} from '../../app/services/futbolProdeApi';
import RankingTableSummary from './RankingTableSummary';

export default function GlobalAreaRankingTableSummary() {
  const { t } = useTranslation();
  return (
    <RankingTableSummary<AreaRanking>
      title={t('sidebar.pages.areasRanking', {
        areaName: t(`user.areaLevel1`, { ns: 'company' }),
      })}
      showAllRoute="positions/areas?areaLevel=1"
      query={useRankingsControllerGlobalRankingByAreaQuery}
      itemName={(row) => row.areaName}
    />
  );
}
