import { useSkeletonReadyList, SkeletonOr } from '@futbolprode/ui-common';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Ranking,
  AreaRanking,
  useRankingsControllerGlobalRankingByAreaQuery,
  useRankingsControllerGlobalRankingQuery,
} from '../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { take } from 'ramda';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCompanyRoutes } from '../../router';

const POSITIONS_TO_SHOW = 10;

export default function RankingTableSummary<T extends Ranking | AreaRanking>({
  title,
  showAllRoute,
  query,
  itemName,
}: {
  title: string;
  showAllRoute: string;
  query:
    | typeof useRankingsControllerGlobalRankingQuery
    | typeof useRankingsControllerGlobalRankingByAreaQuery;
  itemName: (item: T) => string;
}) {
  const { t } = useTranslation();
  const { appRoute } = useCompanyRoutes();
  const { data, isLoading } = query(
    {
      take: POSITIONS_TO_SHOW,
      skip: 0,
    },
    { refetchOnMountOrArgChange: true },
  );

  const positions = useSkeletonReadyList(
    data?.data,
    isLoading,
    POSITIONS_TO_SHOW,
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    backgroundColor: theme.palette.action.hover,
    border: 0,
    '& td': {
      border: 0,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Link
          to={appRoute(showAllRoute)}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Typography sx={{ cursor: 'pointer', textTransform: 'uppercase' }}>
            {t('ranking.showAll')}
          </Typography>
        </Link>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                {t('ranking.positionAbbreviation')}
              </StyledTableCell>
              <StyledTableCell>{t('ranking.name')}</StyledTableCell>
              <StyledTableCell align="right">
                {t('ranking.points')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {take(POSITIONS_TO_SHOW)(positions).map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  <SkeletonOr
                    isLoading={isLoading}
                    value={`${row?.position}°`}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {/* @ts-ignore */}
                  <SkeletonOr isLoading={isLoading} value={itemName(row)} />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <SkeletonOr
                    isLoading={isLoading}
                    value={
                      (row?.points ?? 0) + (row?.['dreamFinalPoints'] ?? 0)
                    }
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
