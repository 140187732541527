import parse from 'html-react-parser';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEmpty, isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import {
  Tournament,
  useCompaniesControllerMyTournamentsQuery,
  useGameRulesControllerTournamentRulesQuery,
} from '../../app/services/futbolProdeApi';
import WithAppBar from '../../common/WithAppBar';
import CentralColumnLayout from '../../common/CentralColumnLayout';

export function GameRulesDashboard() {
  const { data: company } = useCompaniesControllerMyTournamentsQuery();
  const { t } = useTranslation();
  const [selectedTournament, setSelectedTournament] = useState(0);
  useEffect(() => {
    if (
      !selectedTournament &&
      !isNil(company?.tournaments) &&
      !isEmpty(company?.tournaments)
    ) {
      setSelectedTournament(company!.tournaments[0].id);
    }
  }, [company, selectedTournament, setSelectedTournament]);
  const { data: tournamentRules } = useGameRulesControllerTournamentRulesQuery(
    selectedTournament ? selectedTournament.toString() : skipToken,
  );
  return (
    <WithAppBar>
      <CentralColumnLayout>
        <Stack>
          <When condition={(company?.tournaments?.length || 0) > 1}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="end"
            >
              <Grid item xs="auto" mr={2}>
                <FormControl variant="standard">
                  <InputLabel id="demo-multiple-name-label">
                    {t('gameRulesLayout.rulesForTournament')}
                  </InputLabel>

                  <Select
                    name="tournament"
                    id="tournament-select"
                    value={selectedTournament}
                    sx={{ minWidth: 200, p: 2 }}
                    fullWidth
                    onChange={(e) => setSelectedTournament(+e.target.value)}
                  >
                    {company?.tournaments.map((aTournament: Tournament) => (
                      <MenuItem key={aTournament.id} value={aTournament.id}>
                        {aTournament.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </When>
          <Typography variant="h3" gutterBottom>
            {t('termsAndConditions.pages.howToPlay')}
          </Typography>
          <TournamentRulesSection
            title="general"
            tournamentRulesHtml={tournamentRules?.generalRules}
          />
          <TournamentRulesSection
            title="penalties"
            tournamentRulesHtml={tournamentRules?.penaltiesRules}
          />
          <TournamentRulesSection
            title="dreamFinal"
            tournamentRulesHtml={tournamentRules?.dreamFinalRules}
          />
        </Stack>
      </CentralColumnLayout>
    </WithAppBar>
  );
}

function TournamentRulesSection({
  title,
  tournamentRulesHtml,
}: {
  title: string;
  tournamentRulesHtml?: string;
}) {
  const { t } = useTranslation();
  if (!tournamentRulesHtml) {
    return <></>;
  }
  return (
    <Stack>
      <Typography variant="h4">{t(`gameRulesLayout.${title}`)}</Typography>
      <Box sx={{ textAlign: 'justify' }}>{parse(tournamentRulesHtml!)}</Box>
    </Stack>
  );
}
