import { useRouteError, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography, Stack } from '@mui/material';
import { useCompanyRoutes } from './router';
import { Trans, useTranslation } from 'react-i18next';
import UpdateIcon from '@mui/icons-material/Update';

export function UnhandledErrorPage() {
  const { t } = useTranslation();
  const { rootRoute } = useCompanyRoutes();
  const navigate = useNavigate();
  const error = useRouteError();
  console.error(error);
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        height: '100vh',
        placeItems: 'center',
        placeContent: 'center',
      }}
    >
      <Box m={4} textAlign="center">
        <Typography variant="h4" my={2}>
          {t('error.title')}
        </Typography>
        <Typography variant="subtitle1" my={4}>
          {t('error.subtitle')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(rootRoute())}
        >
          {t('error.goHome')}
        </Button>
      </Box>
    </Container>
  );
}

export function MaintenanceModePage() {
  const { t } = useTranslation();

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        height: '100vh',
        placeItems: 'center',
        placeContent: 'center',
      }}
    >
      <Stack spacing={2} textAlign="center" alignItems="center">
        <UpdateIcon color="primary" sx={{ fontSize: 70 }} />
        <Typography variant="h4">{t('unhandledErrorPage.title')}</Typography>
        <Typography variant="subtitle1">
          <Trans
            i18nKey="unhandledErrorPage.subtitle"
            components={{ p: <p style={{ margin: 0 }} /> }}
          />
        </Typography>
        <Stack>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
          >
            {t('unhandledErrorPage.reload')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
