import { useParams } from 'react-router-dom';
import { RoundedCard } from '../../../common/MuiExtensions';
import { CardContent, Stack } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { Switch, Case } from 'react-if';
import PlayWelcomeCard from './PlayWelcomeCard';
import { useEffect, useMemo } from 'react';
import PlaySummaryCard from './PlaySummaryCard';
import PlayCard from './PlayCard';
import TriviaRankingSection from '../TriviaRankingSection';
import SplitLayout from '../../../layouts/SplitLayout';
import {
  useTriviaSolutionsControllerFofeitTriviaSolutionMutation,
  useTriviaSolutionsControllerGetSolutionQuery,
} from '../../../app/services/futbolProdeApi';

export default function TriviaPlayPage() {
  const { triviaId } = useParams();

  const [forfeitSolution] =
    useTriviaSolutionsControllerFofeitTriviaSolutionMutation();

  // Esta query dispara la carga de la trivia y luego nos manejamos siempre con el playingTrivia slice.
  // El refetch está para que el estado se reinicie al cambiar de trivia.
  const { isFetching } = useTriviaSolutionsControllerGetSolutionQuery(
    +triviaId!,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { solution, trivia, finishedPlaying, shouldForfeitSolution } =
    useAppSelector((state) => state.playingTrivia);
  const status = useMemo(() => solution?.status ?? 'not-started', [solution]);
  const isFinalStatus =
    trivia?.playableStatus === 'finished' ||
    (status === 'finished' && finishedPlaying) ||
    status === 'forfeited';

  // Esto contempla el caso de que el usuario recargue la página en medio de la trivia
  // y aún no se haya llegado a ejecutar el job que hace el forfeit automático.
  useEffect(() => {
    if (shouldForfeitSolution) {
      forfeitSolution({ triviaId: +triviaId!, id: solution!.id });
    }
  }, [forfeitSolution, shouldForfeitSolution, solution, triviaId]);

  return (
    <SplitLayout
      title={trivia?.title}
      titleProps={{ fontWeight: 'regular' }}
      HeaderContent={
        <Stack pb={10} justifyContent="center" alignItems="center">
          <RoundedCard sx={{ width: '95%', maxWidth: 700 }}>
            <CardContent
              sx={{
                minHeight: 400,
                display: 'flex',
                alignItems:
                  status === 'not-started' || finishedPlaying
                    ? 'center'
                    : 'flex-start',
                justifyContent: 'center',
                px: 5,
                py: 2,
              }}
            >
              <Switch>
                {/* La PlayCard es la única con un estado de loading piola */}
                <Case condition={isFetching}>
                  <PlayCard isLoading={isFetching} />
                </Case>
                <Case condition={isFinalStatus}>
                  <PlaySummaryCard />
                </Case>
                <Case condition={status === 'not-started'}>
                  <PlayWelcomeCard />
                </Case>
                <Case condition={status === 'started' || !finishedPlaying}>
                  <PlayCard isLoading={isFetching} />
                </Case>
              </Switch>
            </CardContent>
          </RoundedCard>
        </Stack>
      }
      MainContent={<TriviaRankingSection triviaId={triviaId!} />}
    />
  );
}
