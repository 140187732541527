import { isNil } from 'ramda';
import { useCompaniesControllerFindMeQuery } from '../../../app/services/futbolProdeApi';
import AdmissionContainer, { AdmissionFormProps } from '../AdmissionContainer';
import { useMemo } from 'react';
import { ExternalIdSignUpForm } from './ExternalIdSignUpForm';
import { EmailSignUpForm } from './EmailSignUpForm';
import { LoginStrategy } from './LoginStrategy';

function NullForm(_props: AdmissionFormProps) {
  return <></>;
}

export default function SignUpPage() {
  const { data: company } = useCompaniesControllerFindMeQuery();

  const [titleKey, Form] = useMemo(() => {
    if (isNil(company)) {
      return ['', NullForm];
    }

    if (company.loginStrategy === LoginStrategy.byExternalId) {
      return ['signup.activateYourAccount', ExternalIdSignUpForm];
    }

    return ['signup.signup', EmailSignUpForm];
  }, [company]);

  return <AdmissionContainer titleKey={titleKey} Form={Form} />;
}
