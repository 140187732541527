import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Unless } from 'react-if';
import { isNil } from 'ramda';
import { SvgIconComponent } from '@mui/icons-material';

const CustomIcon = ({ icon: IconComponent }: { icon: SvgIconComponent }) => {
  return <IconComponent color="tertiary" sx={{ fontSize: '4rem' }} />;
};

export default function TriviaSplashScreen({
  titleKey,
  subtitleKey,
  children,
  actions,
  icon,
}: {
  titleKey: string;
  subtitleKey: string;
  icon: SvgIconComponent;
  children?: JSX.Element | JSX.Element[];
  actions?: JSX.Element;
}) {
  const { t } = useTranslation();
  return (
    <Stack spacing={1} alignItems="center">
      <CustomIcon icon={icon} />
      <Typography variant="h4">{t(titleKey)}</Typography>
      <Typography variant="subtitle1" textAlign="center">
        {t(subtitleKey)}
      </Typography>
      <Unless condition={isNil(children)}>{children}</Unless>
      <Unless condition={isNil(actions)}>
        <Box pt={6}>{actions}</Box>
      </Unless>
    </Stack>
  );
}
