import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isNil } from 'ramda';
import { useMemo, useState } from 'react';
import {
  useTriviaRankingsControllerGlobalTriviaRankingQuery,
  useTriviaRankingsControllerSingleTriviaRankingQuery,
} from '../../app/services/futbolProdeApi';

interface TriviaRankingQuery {
  triviaId?: string;
}

function usePagination(pageSize = 20) {
  const [skip, setSkip] = useState(0);

  const setPage = (page: number) => setSkip((page - 1) * pageSize);
  const page = useMemo(() => Math.floor(skip / pageSize) + 1, [skip, pageSize]);

  return { page, setPage, skip, take: pageSize };
}

export function useTriviaRankingQuery({ triviaId }: TriviaRankingQuery) {
  const { skip, take, page, setPage } = usePagination(20);

  const globalTriviaRanking =
    useTriviaRankingsControllerGlobalTriviaRankingQuery(
      isNil(triviaId) ? { skip, take } : skipToken,
    );

  const singleTriviaRanking =
    useTriviaRankingsControllerSingleTriviaRankingQuery(
      isNil(triviaId) ? skipToken : { triviaId, skip, take },
    );

  return {
    query: isNil(triviaId) ? globalTriviaRanking : singleTriviaRanking,
    page,
    setPage,
  };
}
