import { ThunkAction, Action, configureStore } from '@reduxjs/toolkit';
import summaryNavigationReducer from '../features/summary/summaryNavigationSlice';
import activeRankingReducer from '../features/rankings/activeRankingSlice';
import playingTriviaReducer from '../features/trivia/play/playingTriviaSlice';
import { defaultReducers, defaultMiddlewares } from '@futbolprode/ui-common';
import { setupListeners } from '@reduxjs/toolkit/query';
import { listenerMiddleware } from './listenerMiddleware';

export const store = configureStore({
  reducer: {
    ...defaultReducers,
    summaryNavigation: summaryNavigationReducer,
    activeRanking: activeRankingReducer,
    playingTrivia: playingTriviaReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(defaultMiddlewares)
      .concat(listenerMiddleware.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
