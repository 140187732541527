import { Chip, Typography, Stack, Skeleton, Grid } from '@mui/material';
import { QuestionStatusChip, QuestionStatusType } from './QuestionStatusChip';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../app/hooks';
import { useMemo } from 'react';
import { count, map, repeat } from 'ramda';
import { RemainingTimeChip } from './RemainingTimeChip';

export default function PlayCardHeader({
  isLoading,
  remainingSeconds,
}: {
  isLoading: boolean;
  remainingSeconds: number;
}) {
  const { currentQuestionIndex, trivia, solution } = useAppSelector(
    (state) => state.playingTrivia,
  );

  const questions = useSkeletonReadyList(trivia?.questions, isLoading, 5);

  const questionStatuses = useMemo(() => {
    const statuses: QuestionStatusType[] = map(
      (answer) => (answer.chosen?.isCorrect ? 'ok' : 'wrong'),
      solution?.answers ?? [],
    );
    const pending: QuestionStatusType[] = repeat(
      'pending',
      Math.max(questions.length - statuses.length, 0),
    );

    return statuses.concat(pending);
  }, [solution, questions]);

  const correctQuestionsCount = useMemo(
    () => count((it) => it.chosen?.isCorrect ?? false, solution?.answers ?? []),
    [solution],
  );

  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Stack alignItems="center">
        <RemainingTimeChip
          value={Math.ceil(remainingSeconds)}
          isLoading={isLoading}
        />
      </Stack>
      <Grid container alignItems="center">
        <Grid item sm={2} display={{ xs: 'none', sm: 'flex' }}>
          <Typography variant="body2">
            {isLoading ? (
              <Skeleton width={100} height={50} />
            ) : (
              t('triviaPage.questionsCount', {
                current: currentQuestionIndex + 1,
                total: questions.length,
              })
            )}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={8}
          columnGap={0.5}
          justifyContent="center"
        >
          {questionStatuses.map((status, index) => (
            <QuestionStatusChip
              key={index}
              status={status}
              isLoading={isLoading}
            />
          ))}
        </Grid>
        <Grid
          item
          container
          sm={2}
          display={{ xs: 'none', sm: 'flex' }}
          justifyContent="flex-end"
        >
          {isLoading ? (
            <Skeleton width={80} height={50} />
          ) : (
            <Chip
              color="success"
              label={`${correctQuestionsCount}/${questions.length}`}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}
