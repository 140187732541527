import {
  Avatar,
  Box,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCompaniesControllerFindMeQuery } from '../app/services/futbolProdeApi';
import { Unless } from 'react-if';

export function SectionTitle({
  title,
  logoUrl,
  titleProps = {},
}: {
  logoUrl?: string;
  titleProps?: TypographyProps;
  title: string;
}) {
  const { data: company } = useCompaniesControllerFindMeQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Box
      display="flex"
      py={4}
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="flex-start"
      alignItems="center"
      textAlign="center"
      gap="2.5%"
    >
      <Unless condition={isMobile}>
        <Avatar
          variant="square"
          src={logoUrl ?? company?.isologoUrl ?? ''}
          alt={'Logo'}
          sx={{ width: 96, height: 96 }}
        />
      </Unless>
      <Typography
        variant="h3"
        color="inherit"
        fontWeight="bold"
        {...titleProps}
      >
        {title}
      </Typography>
    </Box>
  );
}
