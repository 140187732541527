import { Alert, Avatar, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import { useAppSelector } from '../../app/hooks';
import { Tournament } from '../../app/services/futbolProdeApi';
import RoundSummary from './RoundSummary';
import {
  useHydrateRoundWithMatches,
  useNoMoreRoundsAvailableQuery,
} from './roundUtils';
import { useCompanyTranslations } from '@futbolprode/ui-common';
import TournamentLogo from '../../common/TournamentLogo';
import PredictionsProgress from './PredictionsProgress';

interface TournamentSummaryProps {
  tournament: Tournament;
}

function NoMoreRoundsAvailableWarning() {
  const { t } = useTranslation();
  return <Alert severity="warning">{t('round.noMoreAvailable')}</Alert>;
}

function TournamentSummary({ tournament }: TournamentSummaryProps) {
  const round = useAppSelector((state) => state.summaryNavigation.round);
  const { tournamentName } = useCompanyTranslations();

  const { data: showNoMoreRoundsWarning } =
    useNoMoreRoundsAvailableQuery(tournament);

  const {
    data: roundWithMatches,
    isFetching,
    currentData,
  } = useHydrateRoundWithMatches(round);

  const areMatchesLoading = useMemo(
    () => isFetching && !currentData,
    [isFetching, currentData],
  );

  return (
    <Paper elevation={1} className="summary-predictions">
      <Stack p={2} spacing={1}>
        <When condition={showNoMoreRoundsWarning}>
          <Stack>
            <NoMoreRoundsAvailableWarning />
          </Stack>
        </When>
        <PredictionsProgress matches={roundWithMatches?.matches} />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{tournamentName(tournament)}</Typography>
          <TournamentLogo>
            <Avatar
              variant="square"
              src={tournament.logoUrl}
              alt={tournament.name}
              sx={{ width: 64, height: 64 }}
            />
          </TournamentLogo>
          <Typography
            variant="h6"
            sx={{
              visibility: 'hidden',
              display: { xs: 'none', md: 'initial' },
            }}
          >
            {tournament.name}
          </Typography>
        </Stack>
        <Stack>
          <RoundSummary
            tournamentId={tournament.id}
            areMatchesLoading={areMatchesLoading}
            round={roundWithMatches}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default TournamentSummary;
