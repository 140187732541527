import { useTriviaSolutionsControllerStartSolvingTriviaMutation } from '../../../app/services/futbolProdeApi';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppSelector } from '../../../app/hooks';
import { isNil } from 'ramda';
import TriviaSplashScreen from './TriviaSplashScreen';
import { useTranslation } from 'react-i18next';
import AvTimerIcon from '@mui/icons-material/AvTimer';

export default function PlayWelcomeCard() {
  const { t } = useTranslation();
  const { trivia } = useAppSelector((state) => state.playingTrivia);
  const [startSolving, { isLoading }] =
    useTriviaSolutionsControllerStartSolvingTriviaMutation();

  const handleClick = () => {
    if (!isNil(trivia)) {
      startSolving({ triviaId: trivia.id, createTriviaSolutionDto: {} });
    }
  };

  return (
    <TriviaSplashScreen
      icon={AvTimerIcon}
      titleKey={t('triviaPage.thinkFast')}
      subtitleKey={t('triviaPage.answerBeforeTimeIsUp')}
      actions={
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleClick}
        >
          {t('triviaPage.playNow')}
        </LoadingButton>
      }
    />
  );
}
