import { isNil } from 'ramda';
import { Company } from '../app/services/futbolProdeApi';
import { juegoAppConfig } from '../juegoAppConfig';

export function setAppManifest(company: Company) {
  const manifestElement = document.querySelector('#app-manifest');

  if (isNil(manifestElement)) {
    return;
  }

  manifestElement.setAttribute(
    'href',
    `${juegoAppConfig.apiUrl}/companies/${company.id}/manifest`,
  );
}
