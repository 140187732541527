import { Grid, useTheme, InputLabel, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { createValidator } from 'class-validator-formik';
import { useEmailsControllerSendExternalSupportMutation } from '../../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { Input } from './Input';
import { ExternalSupportEmailDto } from './ExternalSupportEmailDto';
import StyledTextarea from '../../../common/StyledTextArea';
import { instanceToPlain } from 'class-transformer';
import { useCompanyCustomColor } from '../../../common/hooks/useCompanyCustomColors';

export function ExternalSupportForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sendExternalSupportEmail, result] =
    useEmailsControllerSendExternalSupportMutation();
  const inquiryValues = new ExternalSupportEmailDto();

  const handleSubmit = async (input: ExternalSupportEmailDto) => {
    await sendExternalSupportEmail(instanceToPlain(input) as any).unwrap();
    navigate(-1);
  };

  const borderColor = useCompanyCustomColor('loginLabelsColor', 'primary.main');

  return (
    <Formik
      initialValues={inquiryValues}
      onSubmit={handleSubmit}
      validate={createValidator(ExternalSupportEmailDto)}
      validateOnChange={false}
    >
      {(formikValues) => {
        const { handleSubmit, handleChange, values, errors } = formikValues;
        return (
          <form onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <Input
                value={values.name}
                name={'name'}
                label={`${t('support.name')} *`}
                onChange={handleChange}
                error={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                value={values.userEmail}
                name={'userEmail'}
                label={`${t('login.email')} *`}
                onChange={handleChange}
                error={errors.userEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                htmlFor={'inquiry'}
                sx={{
                  color: 'inherit',
                  marginBottom: 1,
                  overflow: 'visible',
                  whiteSpace: 'normal',
                }}
              >
                {`${t('support.inquiry')} *`}
              </InputLabel>
              <StyledTextarea
                name="inquiry"
                value={values.inquiry}
                onChange={handleChange}
                minRows={5}
                sx={{
                  borderColor,
                  backgroundColor: 'transparent',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={result.isLoading}
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  fontWeight: 600,
                  textTransform: 'capitalize',
                  marginTop: 5,
                }}
              >
                {t('common.send')}
              </LoadingButton>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate(-1)}
                sx={{ textTransform: 'initial' }}
                fullWidth
              >
                {t('common.goBack')}
              </Button>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}
