import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { isEmpty } from 'ramda';
import { useMemo } from 'react';

export type SearchTextFieldProps = TextFieldProps;

export default function SearchTextField({
  label,
  value,
  onChange,
}: SearchTextFieldProps) {
  const shrink = useMemo(() => !isEmpty(value), [value]);
  return (
    <TextField
      size="small"
      label={label}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        style: shrink
          ? undefined
          : {
              transform: 'translate(64px, 8px) scale(1)',
            },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiInputBase-root': { borderRadius: '50px' },
        ...(shrink
          ? {}
          : {
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
            }),
      }}
    />
  );
}
