import { Box, TypographyProps } from '@mui/material';
import ScrollToTopFab from './ScrollToTopFab';
import { AppContainer } from './AppContainer';
import { Unless } from 'react-if';
import { isNil } from 'ramda';
import { SectionTitle } from './SectionTitle';

export interface SplitLayoutProps {
  title?: string | null;
  titleProps?: TypographyProps;
  logoUrl?: string;
  HeaderContent: JSX.Element;
  MainContent?: JSX.Element | JSX.Element[];
}

export default function SplitLayout({
  title,
  titleProps = {},
  logoUrl,
  HeaderContent,
  MainContent,
}: SplitLayoutProps) {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
        }}
      >
        <AppContainer sx={{ backgroundColor: 'secondary.main' }}>
          <Unless condition={isNil(title)}>
            {SectionTitle({ logoUrl, titleProps, title: title! })}
          </Unless>
          {HeaderContent}
        </AppContainer>
      </Box>
      <Unless condition={isNil(MainContent)}>
        <AppContainer sx={{ backgroundColor: 'transparent' }}>
          <Box my={{ xs: 2, md: 6 }}>{MainContent}</Box>
        </AppContainer>
      </Unless>
      <ScrollToTopFab />
    </>
  );
}
