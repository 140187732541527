import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack, styled, CardProps } from '@mui/material';
import { SkeletonOr, useI18nHelpers } from '@futbolprode/ui-common';
import { Unless } from 'react-if';
import { useTranslation } from 'react-i18next';
import { Routes, useCompanyRoutes } from '../../router';
import { EnhancedTrivia } from './triviaExtensions';

interface TriviaSelectorCardProps {
  value: EnhancedTrivia;
  isLoading: boolean;
  small?: boolean;
}

const RoundedCard = styled(Card)<CardProps>(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 4,
}));

export default function TriviaSelectorCard({
  value,
  isLoading,
  small = false,
}: TriviaSelectorCardProps) {
  const { formatDate } = useI18nHelpers();
  const toHumanDate = (date?: string) => formatDate('PPP', new Date(date ?? 0));

  const { t } = useTranslation();
  const { appRoute } = useCompanyRoutes();

  return (
    <RoundedCard
      sx={{
        height: { xs: small ? 100 : 200, md: small ? 200 : 300 },
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'secondary.light',
      }}
    >
      <Unless condition={isLoading}>
        <CardMedia
          component="img"
          alt="trivia logo"
          height="140"
          image={value.coverImageUrl}
          sx={{
            position: 'absolute',
            filter: 'brightness(0.6)',
            top: 0,
            right: 0,
            height: '100%',
            width: '100%',
          }}
        />
      </Unless>
      <Stack>
        <CardContent sx={{ position: 'relative' }}>
          <Typography
            gutterBottom
            variant={small ? 'h5' : 'h4'}
            color="white"
            textAlign="center"
          >
            <SkeletonOr isLoading={isLoading} value={value.title} width={300} />
          </Typography>
          <Unless condition={value.playableStatus === 'finished'}>
            <Typography
              gutterBottom
              variant="subtitle1"
              color="white"
              textAlign="center"
            >
              <SkeletonOr
                isLoading={isLoading}
                value={
                  value.playableStatus === 'started'
                    ? t('triviaPage.finishesAt', {
                        date: toHumanDate(value.finishesAt),
                      })
                    : t('triviaPage.startsAt', {
                        date: toHumanDate(value.startsAt),
                      })
                }
                width={300}
              />
            </Typography>
          </Unless>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size="small"
            color="success"
            variant="contained"
            disabled={isLoading || value.playableStatus === 'comingSoon'}
            sx={{
              width: 150,
              '&.Mui-disabled': { backgroundColor: 'success.dark' },
            }}
            href={appRoute(`${Routes.TRIVIAS}/${value.id}`)}
          >
            {isLoading
              ? '...'
              : t(`triviaPage.actions.${value.playableStatus}`)}
          </Button>
        </CardActions>
      </Stack>
    </RoundedCard>
  );
}
