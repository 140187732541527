import SplitLayout from '../../layouts/SplitLayout';
import {
  useCompaniesControllerFindMeQuery,
  useTriviasControllerGetCompanyTriviasQuery,
} from '../../app/services/futbolProdeApi';
import { useSkeletonReadyList } from '@futbolprode/ui-common';
import TriviaRankingSection from './TriviaRankingSection';
import { useTranslation } from 'react-i18next';
import { EnhancedTrivia } from './triviaExtensions';
import TriviaShowcaseSection from './TriviaShowcaseSection';

export default function TriviasHomePage() {
  const { t } = useTranslation();
  const { data: company } = useCompaniesControllerFindMeQuery();
  const { data, isLoading } =
    useTriviasControllerGetCompanyTriviasQuery('published');
  const triviasList = useSkeletonReadyList(
    data,
    isLoading,
    2,
  ) as EnhancedTrivia[]; // TODO: hacer que tome el tipo correctamente

  return (
    <SplitLayout
      title={t('triviaPage.title', { company: company?.name }) as string}
      HeaderContent={
        <TriviaShowcaseSection items={triviasList} isLoading={isLoading} />
      }
      MainContent={<TriviaRankingSection />}
    />
  );
}
