import { useTheme } from '@mui/material';
import { useCompaniesControllerFindMeQuery } from '../../app/services/futbolProdeApi';
import { useMemo } from 'react';
import { path } from 'ramda';

export function useCompanyCustomColor(key: string, defaultValue: string) {
  const { data: company } = useCompaniesControllerFindMeQuery();
  const { palette } = useTheme();
  const color: string = useMemo(
    () =>
      path(key.split('.'), company?.customColors) ??
      path(defaultValue.split('.'), palette) ??
      palette.primary.main,
    [company?.customColors, defaultValue, key, palette],
  );

  return color;
}

export function useCompanyCustomBackgroundColor(
  key: string,
  defaultValue: string,
) {
  const { palette } = useTheme();
  const backgroundColor = useCompanyCustomColor(key, defaultValue);
  const color = useMemo(
    () => palette.getContrastText(backgroundColor),
    [backgroundColor, palette],
  );

  return { color, backgroundColor };
}
