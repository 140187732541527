import parse from 'html-react-parser';
import { Stack, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGameRulesControllerTermsAndConditionsQuery } from '../../app/services/futbolProdeApi';
import WithAppBar from '../../common/WithAppBar';
import CentralColumnLayout from '../../common/CentralColumnLayout';

export function TermsAndConditionsLayout() {
  const { data: termsAndConditions } =
    useGameRulesControllerTermsAndConditionsQuery();
  const { t } = useTranslation();
  return (
    <WithAppBar>
      <CentralColumnLayout>
        <Stack>
          <Typography variant="h3" gutterBottom>
            {t(`gameRulesLayout.termsAndConditions`)}
          </Typography>
          <Box sx={{ textAlign: 'justify' }}>
            {parse(termsAndConditions?.termsAndConditions ?? '')}
          </Box>
        </Stack>
      </CentralColumnLayout>
    </WithAppBar>
  );
}
