import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { futbolProdeApi, MatchDate } from '../../app/services/futbolProdeApi';
import { RootState } from '../../app/store';
import { RoundOrCombinedRound } from '../calendar/RoundsCarousel';
import { startAppListening } from '../../app/listenerMiddleware';

type Visualization = 'round' | 'date';

const STORAGE_KEY = 'FP_SUMMARY_VISUALIZATION';

const summaryNavigationSlice = createSlice({
  name: 'summaryNavigation',
  initialState: {
    round: {} as RoundOrCombinedRound,
    initialRound: {} as RoundOrCombinedRound,
    date: {} as MatchDate,
    initialDate: {} as MatchDate,
    activeVisualization: (localStorage.getItem(STORAGE_KEY) ??
      'round') as Visualization,
  },
  reducers: {
    setCurrentRound(state, action: PayloadAction<RoundOrCombinedRound>) {
      state.round = action.payload;
    },
    setCurrentDate(state, action: PayloadAction<MatchDate>) {
      state.date = action.payload;
    },
    setActiveVisualization(state, action: PayloadAction<Visualization>) {
      state.activeVisualization = action.payload;
    },
    restartVisualization(state) {
      if (state.activeVisualization === 'date') {
        state.date = state.initialDate;
      } else {
        state.round = state.initialRound;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      futbolProdeApi.endpoints.roundsControllerFindRecentRoundsFor
        .matchFulfilled,
      (state, { payload: { currentRound } }) => {
        // if current rounds have been set, do not re-set them
        state.initialRound = currentRound;
        if (state.round?.id !== undefined) return;
        state.round = currentRound;
      },
    );

    builder.addMatcher(
      futbolProdeApi.endpoints.matchesControllerFindRecentDatesFor
        .matchFulfilled,
      (state, { payload: { currentRound } }) => {
        // if current rounds have been set, do not re-set them
        state.initialDate = currentRound;
        if (state.date?.id !== undefined) return;
        state.date = currentRound;
      },
    );
  },
});

export const selectDisableRestartButton = (state: RootState) => {
  const {
    summaryNavigation: {
      activeVisualization,
      date,
      initialDate,
      round,
      initialRound,
    },
  } = state;
  return activeVisualization === 'date'
    ? date.id === initialDate.id
    : round.id === initialRound.id;
};

const { actions, reducer } = summaryNavigationSlice;

export const {
  setCurrentRound,
  setCurrentDate,
  setActiveVisualization,
  restartVisualization,
} = actions;

export default reducer;

startAppListening({
  actionCreator: summaryNavigationSlice.actions.setActiveVisualization,
  effect: (action) => {
    localStorage.setItem(STORAGE_KEY, action.payload);
  },
});
