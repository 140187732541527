import { Button, Stack } from '@mui/material';
import { components } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useUsersControllerUpdateMutation } from '../../app/services/futbolProdeApi';
import { useAuth } from '@futbolprode/ui-common';
import LoadingButton from '@mui/lab/LoadingButton';
import { toggleUserProfileMenuOpen } from '../../common/WithAppBar';
import { delay } from '../../common/timeUtils';

/*
Reactour no exporta este tipo, por eso le puse any:

type NavigationProps = BaseProps & {
  setCurrentStep: Dispatch<React__default.SetStateAction<number>>;
  steps: StepType[];
  currentStep: number;
  disableDots?: boolean;
  nextButton?: (props: BtnFnProps) => ReactNode | null;
  prevButton?: (props: BtnFnProps) => ReactNode | null;
  setIsOpen: Dispatch<React__default.SetStateAction<Boolean>>;
  hideButtons?: boolean;
  hideDots?: boolean;
  disableAll?: boolean;
  rtl?: boolean;
  Arrow?: ComponentType<ArrowProps>;
};
*/

export function TourNavigation(props: any) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { setIsOpen, steps, currentStep, setCurrentStep } = props;
  const [updateUser, updateResult] = useUsersControllerUpdateMutation();

  const closeTour = useCallback(async () => {
    await updateUser({
      id: user!.id,
      updateUserDto: { showInitialTour: false },
    }).unwrap();
    setIsOpen(false);
  }, [setIsOpen, updateUser, user]);

  const goToLastStep = useCallback(async () => {
    // HACK: nos aseguramos de que el menú esté abierto.
    toggleUserProfileMenuOpen();
    await delay(0.1);
    setCurrentStep(steps.length - 1);
  }, [setCurrentStep, steps.length]);

  const goToNextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [setCurrentStep, currentStep]);

  const isLastStep = useMemo(
    () => currentStep === steps.length - 1,
    [steps.length, currentStep],
  );

  const ActionButton = useMemo(
    () => (
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="text"
          color="tertiary"
          onClick={goToLastStep}
          disabled={isLastStep}
        >
          {isLastStep ? '' : t('tour.skip')}
        </Button>
        <LoadingButton
          loading={updateResult.isLoading}
          variant="contained"
          color="tertiary"
          onClick={isLastStep ? closeTour : goToNextStep}
        >
          {t(isLastStep ? 'tour.startPlaying' : 'tour.nextStep')}
        </LoadingButton>
      </Stack>
    ),
    [
      closeTour,
      goToLastStep,
      goToNextStep,
      isLastStep,
      t,
      updateResult.isLoading,
    ],
  );

  return (
    <Stack spacing={2}>
      <components.Navigation {...props} />
      {ActionButton}
    </Stack>
  );
}
