import {
  Chip,
  Grid,
  Skeleton,
  Link as MuiLink,
  Typography,
  Divider,
} from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import {
  NewsDetails,
  useNewsControllerFindOneQuery,
  useNewsControllerFindOneRssByExternalidQuery,
} from '../../../app/services/futbolProdeApi';
import CentralColumnLayout from '../../../common/CentralColumnLayout';
import * as DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { Unless } from 'react-if';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useCompanyRoutes } from '../../../router';

const forbiddenTags = (isCustomNews: boolean) => {
  const baseTags = ['figure', 'img', 'blockquote', 'br'];
  return isCustomNews ? baseTags : ['a', ...baseTags];
};

export default function FullPageNewsItemView() {
  const { appRoute } = useCompanyRoutes();
  const { t } = useTranslation();
  const { newsId } = useParams();
  const [urlParams] = useSearchParams();

  const isCustomNews = !isNil(newsId);
  const externalIdQueryParam = urlParams.get('externalId');
  const sourceQueryParam = urlParams.get('source');
  const { data: newsItem, isLoading: newsIsLoading } =
    useNewsControllerFindOneQuery(newsId!, {
      skip: !isCustomNews,
    });
  const {
    data: rssNewsItem,
    isLoading: rssIsLoading,
    isError,
  } = useNewsControllerFindOneRssByExternalidQuery(
    { externalId: externalIdQueryParam!, source: sourceQueryParam! },
    {
      skip: !externalIdQueryParam || !sourceQueryParam,
    },
  );
  const thisNews: NewsDetails | undefined = isCustomNews
    ? newsItem
    : rssNewsItem;
  const isLoading = newsIsLoading || rssIsLoading;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <CentralColumnLayout>
      {isError ? (
        <Grid
          container
          spacing={4}
          alignContent="flex-start"
          textAlign="center"
        >
          <Grid item xs={12}>
            <Typography variant="h4">{t('news.notAvailable')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              {t('news.seeOther')}{' '}
              <Link to={appRoute('news')}>{t('news.here')}</Link>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {isLoading ? <Skeleton /> : thisNews?.title ?? ''}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight="regular"
                color="textSecondary"
              >
                {isLoading ? <Skeleton /> : thisNews?.summary ?? ''}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <img
                src={thisNews?.imageUrl ?? ''}
                alt={thisNews?.title}
                width="100%"
              />
            </Grid>
            <Unless condition={isNil(thisNews?.category)}>
              <Grid item xs={12}>
                <Chip label={thisNews?.category} />
              </Grid>
            </Unless>
            <Grid item xs={12} sx={{ textAlign: 'justify' }}>
              {parse(
                DOMPurify.sanitize(
                  thisNews?.description
                    ?.replaceAll(/\\n|\\t/g, '')
                    ?.replaceAll(`\\"`, '"') ?? '',
                  {
                    FORBID_TAGS: forbiddenTags(isCustomNews),
                    FORBID_CONTENTS: ['figure', 'blockquote'],
                    FORBID_ATTR: ['style'],
                    USE_PROFILES: { html: true },
                  },
                ),
                { trim: true },
              )}
            </Grid>
          </Grid>
          <Unless condition={isNil(thisNews?.source)}>
            <Grid item xs={12}>
              <Divider />
              <Typography
                variant="body2"
                color="textSecondary"
                textAlign="right"
                mt={2}
              >
                {t('news.publishedBy') + ' '}
                <MuiLink
                  href={thisNews?.link ?? '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>{thisNews?.source}</strong>
                </MuiLink>
              </Typography>
            </Grid>
          </Unless>
        </>
      )}
    </CentralColumnLayout>
  );
}
