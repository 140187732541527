import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import {
  futbolProdeApi,
  TriviaSolution,
} from '../../../app/services/futbolProdeApi';
import { isNil } from 'ramda';
import { EnhancedTrivia, enhanceTrivia } from '../triviaExtensions';

const makeInitialState = () => ({
  currentQuestionIndex: 0,
  trivia: undefined as EnhancedTrivia | undefined,
  solution: undefined as TriviaSolution | undefined,
  shouldForfeitSolution: false,
  finishedPlaying: false,
  disableAnswerSubmit: false,
});

const playingTriviaSlice = createSlice({
  name: 'playingTrivia',
  initialState: makeInitialState(),
  reducers: {
    triviaNextQuestion(state) {
      if (triviaHasMoreQuestions(state)) {
        state.currentQuestionIndex += 1;
      } else {
        state.finishedPlaying = true;
      }
      state.disableAnswerSubmit = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      futbolProdeApi.endpoints.triviaSolutionsControllerGetSolution
        .matchFulfilled,
      (state, { payload }) => {
        const { trivia, solution } = payload;

        // Esto contempla el caso de que el usuario recargue la página en medio de la trivia
        // y aún no se haya llegado a ejecutar el job que hace el forfeit automático.
        if (isNil(state.solution) && solution?.status === 'started') {
          state.shouldForfeitSolution = true;
        }

        state.trivia = enhanceTrivia(trivia);
        state.solution = solution;
        state.currentQuestionIndex = 0;

        if (solution?.status !== 'started') {
          state.finishedPlaying = true;
        }
      },
    );

    builder.addMatcher(
      futbolProdeApi.endpoints.triviaSolutionsControllerFofeitTriviaSolution
        .matchFulfilled,
      (state) => {
        state.shouldForfeitSolution = false;
      },
    );

    builder.addMatcher(
      futbolProdeApi.endpoints.triviaSolutionsControllerStartSolvingTrivia
        .matchFulfilled,
      (state, { payload }) => {
        state.solution = payload;
      },
    );

    builder.addMatcher(
      futbolProdeApi.endpoints.triviaSolutionsControllerAnswerTriviaQuestion
        .matchFulfilled,
      (state, { payload }) => {
        state.solution = payload.solution;
      },
    );

    builder.addMatcher(
      futbolProdeApi.endpoints.triviaSolutionsControllerAnswerTriviaQuestion
        .matchPending,
      (state) => {
        state.disableAnswerSubmit = true;
      },
    );

    builder.addMatcher(
      futbolProdeApi.endpoints.triviaSolutionsControllerDeleteTriviaSolution
        .matchFulfilled,
      (state) => ({ ...makeInitialState(), trivia: state.trivia }),
    );
  },
});

const triviaHasMoreQuestions = (state: RootState['playingTrivia']) =>
  state.currentQuestionIndex < (state.trivia?.questions.length ?? 0) - 1;

const triviaCurrentQuestion = (state: RootState['playingTrivia']) =>
  state.trivia?.questions[state.currentQuestionIndex];

export const selectTriviaCurrentQuestion = (state: RootState) =>
  triviaCurrentQuestion(state.playingTrivia);

export const selectTriviaCurrentAnswer = (state: RootState) =>
  (state.playingTrivia.solution?.answers ?? []).find(
    (it) => it.question.id === triviaCurrentQuestion(state.playingTrivia)?.id,
  );

export const selectTriviaHasMoreQuestions = (state: RootState) =>
  triviaHasMoreQuestions(state.playingTrivia);

const { actions, reducer } = playingTriviaSlice;
export const { triviaNextQuestion } = actions;
export default reducer;
