import { styled } from '@mui/material';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

export interface QuestionChoiceButtonProps extends LoadingButtonProps {
  isDisabled: boolean;
}

export const QuestionChoiceButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})<QuestionChoiceButtonProps>(({ theme, isDisabled }) => ({
  textTransform: 'initial',
  justifyContent: 'flex-start',
  borderRadius: theme.shape.borderRadius * 2,
  borderColor: theme.palette.grey[400],
  padding: '10px 15px',
  ...(isDisabled ? { cursor: 'default', pointerEvents: 'none' } : {}),
}));
