import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

function EmptyLayout() {
  return (
    <Box>
      <Outlet />
    </Box>
  );
}

export default EmptyLayout;
