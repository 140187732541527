import { baseApi } from '@futbolprode/ui-common';
import {
  futbolProdeApi,
  TriviasControllerGetCompanyTriviasApiResponse,
} from './futbolProdeApi';
import { isNil } from 'ramda';
import { encode } from 'universal-base64url';
import {
  EnhancedTrivia,
  enhanceTrivia,
} from '../../features/trivia/triviaExtensions';

export function enhanceEndpoints() {
  futbolProdeApi.enhanceEndpoints({
    endpoints: {
      triviaSolutionsControllerDeleteTriviaSolution: {
        invalidatesTags: ['triviaRankings'],
      },
      triviaSolutionsControllerAnswerTriviaQuestion: {
        invalidatesTags: ['triviaRankings'],
      },
      triviasControllerGetCompanyTrivias: {
        transformResponse: (
          response: TriviasControllerGetCompanyTriviasApiResponse,
        ): EnhancedTrivia[] => response.map(enhanceTrivia),
      },
      usersControllerUpdate: {
        invalidatesTags: ['gamerules'],
      },
      newsControllerCombinedNews: {
        // Esta query trae todas las noticias completas y es un poco lenta porque parsea los feeds en el momento.
        // Para no volver a hacerla por cada noticia, actualizamos a mano la caché de las
        // noticias individuales.
        async onQueryStarted(_void, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            data
              .filter((it) => !isNil(it.externalId))
              .forEach((it) => {
                dispatch(
                  futbolProdeApi.util.upsertQueryData(
                    'newsControllerFindOneRssByExternalid',
                    {
                      externalId: encode(it.externalId!),
                      source: encode(it.source!),
                    },
                    it,
                  ),
                );
              });
          } catch {}
        },
      },
    },
  });
}

export { baseApi };
