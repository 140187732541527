import { Avatar } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridNoRowsOverlay,
  GridOverlay,
} from '@mui/x-data-grid';
import {
  useCompaniesControllerFindMeQuery,
  useUsersControllerFindMeQuery,
} from '../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { darken, lighten, styled } from '@mui/material/styles';
import { curry } from 'ramda';

const softenColor = curry((coefficient: number, color: string, mode: string) =>
  mode === 'dark' ? darken(color, coefficient) : lighten(color, coefficient),
);

const getBackgroundColor = softenColor(0.8);
const getHoverBackgroundColor = softenColor(0.7);
const getSelectedBackgroundColor = softenColor(0.6);
const getSelectedHoverBackgroundColor = softenColor(0.5);

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  const baseColor = theme.palette.tertiary.light;
  return {
    '& .ranking-row-me': {
      backgroundColor: getBackgroundColor(baseColor, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(baseColor, theme.palette.mode),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          baseColor,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            baseColor,
            theme.palette.mode,
          ),
        },
      },
    },
  };
});

type RankingDataGridProps = DataGridProps;

export default function RankingDataGrid({
  rows,
  getRowId,
  columns,
  loading,
}: RankingDataGridProps) {
  const { t } = useTranslation();
  const { data: company } = useCompaniesControllerFindMeQuery();
  const { data: user } = useUsersControllerFindMeQuery();
  const sharedColumns: GridColDef[] = [
    {
      field: 'position',
      // @ts-expect-error
      headerName: (
        <Avatar
          src={company?.isologoUrl ?? ''}
          alt="logo"
          sx={{ width: '48px', height: '48px' }}
        />
      ),
      description: '',
      type: 'number',
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <StyledDataGrid
      rows={company?.disableRankingsTable ? [] : rows}
      getRowId={getRowId}
      columns={sharedColumns.concat(columns)}
      pageSizeOptions={[20]}
      loading={loading}
      autoHeight
      hideFooter
      getRowClassName={({ row }) =>
        row.user?.id === user?.id ? 'ranking-row-me' : ''
      }
      initialState={{
        sorting: {
          sortModel: [{ field: 'position', sort: 'asc' }],
        },
      }}
      slots={{
        noRowsOverlay: () =>
          company?.disableRankingsTable ? (
            <GridOverlay>{t('ranking.positions.comingSoon')}</GridOverlay>
          ) : (
            <GridNoRowsOverlay />
          ),
      }}
    />
  );
}
