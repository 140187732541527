import { Chip, Skeleton, Typography } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { selectTriviaCurrentAnswer } from './playingTriviaSlice';
import { useMemo } from 'react';
import { isNil } from 'ramda';
import { chipNoLabelSx, circleChipSx } from '../../../common/MuiExtensions';
import { Check, Clear } from '@mui/icons-material';

export const RemainingTimeChip = ({
  value,
  isLoading,
}: {
  value: number;
  isLoading: boolean;
}) => {
  const diameter = '50px';

  const currentAnswer = useAppSelector(selectTriviaCurrentAnswer);
  const showTimer = useMemo(() => isNil(currentAnswer), [currentAnswer]);
  const color = useMemo(
    () =>
      showTimer
        ? 'tertiary'
        : currentAnswer?.chosen?.isCorrect
        ? 'success'
        : 'error',
    [showTimer, currentAnswer],
  );
  const icon = useMemo(
    () =>
      showTimer ? undefined : currentAnswer?.chosen?.isCorrect ? (
        <Check />
      ) : (
        <Clear />
      ),
    [showTimer, currentAnswer],
  );
  const sx = useMemo(
    () => ({
      ...circleChipSx(diameter),
      ...(showTimer
        ? { '& .MuiChip-label': { overflow: 'inherit' } }
        : chipNoLabelSx),
    }),
    [showTimer],
  );
  const label = useMemo(
    () =>
      showTimer ? <Typography variant="h4">{value}</Typography> : undefined,
    [showTimer, value],
  );

  return isLoading ? (
    <Skeleton variant="circular" width={diameter} height={diameter} />
  ) : (
    <Chip sx={sx} color={color} label={label} icon={icon} />
  );
};
