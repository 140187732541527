import { Box, Stack, Typography } from '@mui/material';
import { TriviaRanking } from '../../app/services/futbolProdeApi';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchTextField from '../../common/rankings/SearchTextInput';
import RankingPagination from '../../common/rankings/RankingPagination';
import RankingDataGrid from '../../common/rankings/RankingDataGrid';
import { useTriviaRankingQuery } from './useTriviaRankingQuery';
import { isNil } from 'ramda';
import useRankingHelpers from '../../common/hooks/useRankingHelpers';

const PAGE_SIZE = 20;

export default function TriviaRankingSection({
  triviaId,
}: {
  triviaId?: string;
}) {
  const [fullNameFilter, setFullNameFilter] = useState('');
  const isGeneralRanking = useMemo(() => isNil(triviaId), [triviaId]);

  const {
    page,
    setPage,
    query: { data, isFetching },
  } = useTriviaRankingQuery({ triviaId });

  const { t } = useTranslation();
  const { userNameForRanking } = useRankingHelpers();

  const Paginator = (
    <RankingPagination
      count={Math.ceil((data?.pagination?.count ?? 0) / PAGE_SIZE)}
      page={page}
      onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
      }}
    />
  );

  // @ts-expect-error
  const columns: GridColDef<TriviaRanking>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('ranking.positions.firstAndLastName'),
        description: t('ranking.positions.firstAndLastName'),
        minWidth: 300,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: (params: GridValueGetterParams) =>
          userNameForRanking(params.row.user),
      },
      {
        field: 'totalElapsedTime',
        headerName: t('trivia.totalElapsedTime'),
        description: t('trivia.totalElapsedTime'),
        minWidth: 100,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'points',
        headerName: t('ranking.positions.points'),
        description: t('ranking.positions.points'),
        minWidth: 100,
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
      },
    ],
    [t, userNameForRanking],
  );

  return (
    <Stack spacing={{ xs: 2, md: 8 }}>
      <Typography variant="h5">
        {t(isGeneralRanking ? 'ranking.global' : 'ranking.trivia')}
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box />
        <SearchTextField
          label={t('ranking.positions.searchUsers')}
          value={fullNameFilter}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFullNameFilter(event.target.value);
          }}
        />
        {Paginator}
      </Box>
      <RankingDataGrid
        rows={data?.data ?? []}
        getRowId={(row) => row.id}
        columns={columns}
        loading={isFetching}
      />
      <Stack direction="row" justifyContent="center">
        {Paginator}
      </Stack>
    </Stack>
  );
}
