import { Stack } from '@mui/material';
import PlayCardHeader from './PlayCardHeader';
import PlayCardQuestion from './PlayCardQuestion';
import { useCountdown } from 'usehooks-ts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectTriviaCurrentQuestion } from './playingTriviaSlice';
import { useAppSelector } from '../../../app/hooks';
import useTriviaPlayHelpers from './useTriviaPlayHelpers';
import ConfirmationModal from '../../../common/ConfirmationModal';
import ReactRouterPrompt from 'react-router-prompt';
import { useTriviaSolutionsControllerFofeitTriviaSolutionMutation } from '../../../app/services/futbolProdeApi';

const timerIntervalMs = 10;
const timerCorrectionFactor = 1000 / timerIntervalMs;

export default function PlayCard({ isLoading }: { isLoading: boolean }) {
  const currentQuestion = useAppSelector(selectTriviaCurrentQuestion);
  const { trivia, solution } = useAppSelector((state) => state.playingTrivia);
  const { answerQuestion } = useTriviaPlayHelpers();
  const [timerExpired, setTimerExpired] = useState(false);
  const [forfeitSolution, forfeitResult] =
    useTriviaSolutionsControllerFofeitTriviaSolutionMutation();

  const forfeitCurrentQuestion = useCallback(async () => {
    await answerQuestion(undefined, 0);
  }, [answerQuestion]);

  const [remainingTime, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: (trivia?.maxTimePerQuestion ?? 0) * timerCorrectionFactor,
      intervalMs: timerIntervalMs,
    });

  const remainingSeconds = useMemo(
    () => remainingTime / timerCorrectionFactor,
    [remainingTime],
  );

  useEffect(() => {
    if (remainingTime === 0 && !timerExpired) {
      setTimerExpired(true);
      forfeitCurrentQuestion();
    }
  }, [remainingTime, timerExpired, forfeitCurrentQuestion]);

  useEffect(() => {
    setTimerExpired(false);
    resetCountdown();
    startCountdown();
    // Si se incluye la función startCountdown, el hook se ejecuta a cada rato.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);

  return (
    <>
      <ReactRouterPrompt
        when={true}
        beforeConfirm={() =>
          forfeitSolution({ triviaId: trivia!.id, id: solution!.id })
        }
      >
        {({ isActive, onConfirm, onCancel }) => (
          <ConfirmationModal
            descriptionKey="triviaPage.confirmLeavePageDescription"
            open={isActive}
            isSubmitting={forfeitResult.isLoading}
            onAccept={onConfirm}
            onCancel={onCancel}
          />
        )}
      </ReactRouterPrompt>
      <Stack spacing={2} width="100%">
        <PlayCardHeader
          isLoading={isLoading}
          remainingSeconds={remainingSeconds}
        />
        <PlayCardQuestion
          isLoading={isLoading}
          remainingSeconds={remainingSeconds}
          onQuestionAnswered={stopCountdown}
        />
      </Stack>
    </>
  );
}
