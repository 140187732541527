import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import getCroppedImg from './imageUtils';
import { useUploadFileMutation } from '@futbolprode/ui-common';
import {
  User,
  useUsersControllerUpdateMutation,
} from '../../app/services/futbolProdeApi';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CropperModal({
  image,
  open,
  setOpen,
  user,
}: {
  image: string;
  open: boolean;
  setOpen: (it: boolean) => void;
  user?: User;
}) {
  const [uploadFile] = useUploadFileMutation();
  const [updateUserPicture] = useUsersControllerUpdateMutation();

  const { t } = useTranslation();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [_croppedImage, setCroppedImage] = useState<any>(null);

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const [isSaving, setIsSaving] = useState(false);

  const saveCroppedImage = useCallback(async () => {
    setIsSaving(true);
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
      );
      setCroppedImage(croppedImage);
      const formData = new FormData();
      formData.append('file', croppedImage as any);
      const { cdnUrl } = await uploadFile(formData).unwrap();
      await updateUserPicture({
        id: user!.id,
        updateUserDto: {
          profilePictureUrl: cdnUrl,
        },
      }).unwrap();
      onClose();
      setIsSaving(false);
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [
    image,
    croppedAreaPixels,
    rotation,
    uploadFile,
    updateUserPicture,
    user,
    onClose,
    setOpen,
  ]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {t('profile.editAvatar.title')}
          </Typography>
          <Box position="relative" sx={{ height: '300px' }}>
            <Cropper
              cropShape="round"
              showGrid={false}
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
            />
          </Box>
          <Typography sx={{ mt: 2 }}>
            {t('profile.editAvatar.description')}
          </Typography>
          <Button onClick={saveCroppedImage} disabled={isSaving}>
            {t('profile.save')}
          </Button>
        </Box>
      </Modal>
    </>
  );
}
