import { Box, Paper } from '@mui/material';
import useIsMobileQuery from './hooks/useIsMobileQuery';

const Container = ({ children }: { children: JSX.Element }) => (
  <Box
    sx={{
      width: '100%',
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    {children}
  </Box>
);

function ColumnLayout({ children }: { children: JSX.Element }) {
  const isMobile = useIsMobileQuery();
  return (
    <Box
      px={{ xs: 2, md: 16 }}
      py={{ xs: 2, md: 1 }}
      sx={{
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
        width: isMobile ? '100%' : '80%',
        alignItems: 'center',
      }}
    >
      <Paper elevation={3} sx={{ p: { xs: 2, md: 8 }, minHeight: '100vh' }}>
        {children}
      </Paper>
    </Box>
  );
}

export default function CentralColumnLayout({
  children,
}: {
  children: JSX.Element;
}) {
  return (
    <Container>
      <ColumnLayout>{children}</ColumnLayout>
    </Container>
  );
}
