import { useState } from 'react';
import { FormHelperText, useTheme, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { createValidator } from 'class-validator-formik';
import {
  CreateUserDto,
  useAuthControllerRegisterMutation,
} from '../../../app/services/futbolProdeApi';
import { useTranslation } from 'react-i18next';
import { Routes, useCompanyRoutes } from '../../../router';
import { SignUpUserDto } from './SignUpUserDto';
import { AdmissionFormProps } from '../AdmissionContainer';
import { PasswordField } from './PasswordField';
import { isEmpty } from 'ramda';
import { Input } from './Input';
import { SignUpExtraFields } from './SignUpExtraFields';
import TermsAndConditionsSection from './TermsAndConditionsSection';

export function EmailSignUpForm({ company }: AdmissionFormProps) {
  const [signup, result] = useAuthControllerRegisterMutation();

  const [showPassword, setShowPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { rootRoute } = useCompanyRoutes();
  const [acceptsTermsAndConditions, setAcceptsTermsAndConditions] =
    useState(false);

  const handleSignUp = async (c: SignUpUserDto) => {
    const createUserDto: CreateUserDto = {
      name: c.name,
      email: c.email,
      password: c.password,
      externalId: c.externalId,
      preferredLanguage: i18n.language,
      customFields: c.extraFields ? JSON.stringify(c.extraFields) : undefined,
    };
    await signup(createUserDto).unwrap();
    const queryString = company?.requiresEmailVerification
      ? `verify=${encodeURIComponent(c.email)}`
      : 'signup=true';
    navigate(rootRoute(`${Routes.LOGIN}?${queryString}`));
  };

  const companyExtraFields = (company?.extraFields as any[]) || [];
  const handleSubmit = (values: SignUpUserDto) => {
    const allRequiredCustomFieldsAreComplete = companyExtraFields
      .filter((it) => it.isRequired)
      .every((it) =>
        ((values.extraFields as { name: string; value: any }[]) || []).some(
          (extraField) =>
            extraField.name === it.name && !isEmpty(it.value?.toString()),
        ),
      );
    if (allRequiredCustomFieldsAreComplete) {
      handleSignUp(values);
    }
  };
  const user: SignUpUserDto = {
    ...new SignUpUserDto(),
    extraFields: companyExtraFields.map((it) => ({
      name: it.name,
      value: it.type === 'boolean' ? false : '',
    })),
  };

  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);

  const validator = createValidator(SignUpUserDto);
  const handleValidation = (input: any) => ({
    ...(repeatedPassword === input.password
      ? {}
      : { password: t('validations.passwords.doNotMatch') }),
    ...validator(input),
  });
  const theme = useTheme();
  return (
    <Formik
      initialValues={user}
      onSubmit={handleSubmit}
      validate={handleValidation}
      validateOnChange={false}
    >
      {(formikProps) => {
        const { handleSubmit, handleChange, values, errors } = formikProps;
        console.log({ errors });
        return (
          <form onSubmit={handleSubmit}>
            <Stack direction="row" spacing={0.5}>
              <span style={{ color: 'inherit' }}>
                {t('signup.alreadyHaveAccountQuestion')}
              </span>
              <Link to={'../' + Routes.LOGIN} style={{ color: 'inherit' }}>
                {t('signup.signin')}
              </Link>
            </Stack>
            <Input
              value={values.name}
              name={'name'}
              label={`${t('signup.name')} *`}
              onChange={handleChange}
              error={errors.name}
            />
            <Input
              value={values.email}
              name={'email'}
              label={`${t('login.email')} *`}
              onChange={handleChange}
              error={errors.email}
            />
            <PasswordField
              value={values.password}
              name={'password'}
              label={`${t('login.password')} *`}
              onChange={handleChange}
              errors={''}
              placeholder={t('login.password')}
              visibilityHook={[showPassword, setShowPassword]}
            />
            <PasswordField
              name="repeatPassword"
              errors={errors.password}
              placeholder={t('login.repeatPassword')}
              value={repeatedPassword}
              onChange={(e: any) => setRepeatedPassword(e.target.value)}
              visibilityHook={[showRepeatedPassword, setShowRepeatedPassword]}
            />
            <SignUpExtraFields {...formikProps} company={company} />
            <FormHelperText error>
              {result.error &&
                ((result.error as any).data?.message ===
                'company_reached_max_users_amount'
                  ? t('signup.errors.company_reached_max_users_amount')
                  : t('signup.userInvalid'))}
            </FormHelperText>
            <TermsAndConditionsSection
              value={acceptsTermsAndConditions}
              onChange={setAcceptsTermsAndConditions}
            />
            <LoadingButton
              loading={result.isLoading}
              fullWidth
              type="submit"
              variant="contained"
              disabled={!acceptsTermsAndConditions}
              sx={{
                backgroundColor: theme.palette.primary.main,
                fontWeight: 600,
                textTransform: 'capitalize',
              }}
            >
              {t('signup.signup')}
            </LoadingButton>
          </form>
        );
      }}
    </Formik>
  );
}
