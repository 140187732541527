import { useTranslation } from 'react-i18next';
import { useCompaniesControllerFindMeQuery } from './app/services/futbolProdeApi';
import { useEffect } from 'react';

export function useCompanyPageTitle() {
  const { data, isLoading } = useCompaniesControllerFindMeQuery();
  const { t } = useTranslation();

  useEffect(() => {
    document.title =
      [
        data?.name,
        t('customization.loginWelcomeMessage', {
          ns: 'company',
          defaultValue: null,
        }),
      ]
        .filter((it) => it)
        .join(' | ') || document.title;
  }, [data, t]);

  return { isLoading };
}
