import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { drop } from 'ramda';
import {
  News,
  useNewsControllerCombinedNewsQuery,
} from '../../../app/services/futbolProdeApi';
import { NewsItemView } from '../NewsItemView';
import { useEffect } from 'react';

export default function NewsHome() {
  const { data, isLoading } = useNewsControllerCombinedNewsQuery();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Grid container spacing={2}>
      {drop<News>(isMobile ? 0 : 3)(data ?? Array(3)).map((it) => (
        <Grid item xs={12} md={4}>
          <NewsItemView key={it.id} news={it} isLoading={isLoading} secondary />
        </Grid>
      ))}
    </Grid>
  );
}
